/**
 * Created by razial on 13.12.2024.
 */

const HoseAdapter = function () {
    this.locations = {};

    cleverapps.user.on("incLevel", this.save.bind(this));
};

HoseAdapter.prototype.load = function () {
    this.updateInfo();
};

HoseAdapter.prototype.save = function (fromSever) {
    // backward compatability for clients with location & progress
    cleverapps.dataLoader.save(DataLoader.TYPES.HOSE, this.getInfo());

    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

HoseAdapter.prototype.updateInfo = function () {
    cleverapps.meta.logic.currentLocationId = levels.user.episode || 0;

    this.locations = {};
    this.locations[cleverapps.meta.logic.currentLocationId] = {
        progress: levels.user.level || 0
    };
};

HoseAdapter.prototype.getInfo = function () {
    return {
        location: levels.user.episode,
        progress: levels.user.level
    };
};

HoseAdapter.prototype.loadLocation = function (locationId) {
    return this.locations[locationId] || {
        progress: locationId < cleverapps.meta.logic.currentLocationId ? 15 : 0
    };
};

HoseAdapter.prototype.saveLocation = function (locationId, data) {
    this.locations[locationId] = data;

    cleverapps.meta.save();
};

HoseAdapter.prototype.reset = function () {
    this.locations = {};
};
