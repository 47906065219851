/**
 * Created by razial on 16.01.2025.
 */

const NoMetaLogic = function () {

};

NoMetaLogic.prototype.reset = function () {

};

NoMetaLogic.prototype.isLocationAvailable = function (locationId) {
    return locationId === 0;
};

NoMetaLogic.prototype.getNextLocation = function () {

};

NoMetaLogic.prototype.getMainLocation = function () {
    return 0;
};

NoMetaLogic.prototype.getLocationClass = function () {
    return NoMetaLocation;
};

NoMetaLogic.prototype.listActiveLocations = function () {
    return [0];
};

NoMetaLogic.prototype.isCompleted = function () {
    return false;
};

NoMetaLogic.prototype.processChatMessage = function () {

};
