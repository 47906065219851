/**
 * Created by slava on 24.03.17.
 */

const FinishEpisodeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (episodeNo) {
        this.episodeNo = episodeNo;

        const styles = cleverapps.styles.FinishEpisodeWindow;

        const text = this.createText();

        const items = [text];

        const image = this.createImage();
        if (image) {
            items.push(image);
        }

        const content = new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });
        
        this._super({
            title: "FinishEpisodeWindow.Title",
            name: "finishepisodewindow",
            content,
            closeButton: true,
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "OK",
                onPressed: this.close.bind(this)
            },
            shareId: "achievement",
            showSound: bundles.finish_episode_window && bundles.finish_episode_window.urls.finish_episode_effect || bundles.main.urls.congratulate_sfx
        });
    },

    createImage: function () {
        if (bundles.finish_episode_window) {
            const node = new cc.Node();
            node.setAnchorPoint(0.5, 0.5);
            node.setContentSize2(cleverapps.styles.FinishEpisodeWindow.animation);

            const animation = new cleverapps.Spine(bundles.finish_episode_window.jsons.episode_completed_json);
            animation.setAnimation(0, "animation", true);
            node.addChild(animation);
            animation.setPositionRound(node.width / 2, node.height / 2);

            return node;
        }

        const bundle = this.findProperBundle(this.episodeNo);

        if (bundle) {
            return new cc.Sprite(bundles[bundle].urls.episode_finish_image);
        }
    },

    createText: function () {
        const styles = cleverapps.styles.FinishEpisodeWindow;

        let message = `FinishEpisodeWindow.Text${this.episodeNo}`;
        if (!Messages.has(message)) {
            message = "FinishEpisodeWindow.Text";
        }

        const text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.FINISH_EPISODE_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        return text;
    },

    onShow: function () {
        this._super();
    },

    findProperBundle: function (episodeNo) {
        const availableBundles = [];
        for (let i = 0; i < 2; i++) {
            const bundle = `finish_episode_${i}`;
            const languageBundle = `${bundle}_${cleverapps.settings.language}`;

            if (bundles[languageBundle]) {
                availableBundles.push(languageBundle);
            } else if (bundles[bundle]) {
                availableBundles.push(bundle);
            } else {
                break;
            }
        }

        return availableBundles[episodeNo % availableBundles.length];
    },

    listBundles: function (episodeNo) {
        const need = ["victory_window"];

        if (bundles.finish_episode_window) {
            need.push("finish_episode_window");
        }

        const proper = this.findProperBundle(episodeNo);
        if (proper) {
            need.push(proper);
        }

        return need;
    }
});

cleverapps.styles.FinishEpisodeWindow = {
    text: {
        width: 600
    },

    margin: 40,

    animation: {
        width: 300,
        height: 300
    },

    button: {
        width: 280,
        height: 110
    }
};