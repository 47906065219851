/**
 * Created by mac on 3/14/18
 */

const PlaySession = function () {
    this.load();

    this.onNotifyActiveListener = function () {};
};

PlaySession.prototype.load = function () {
    let info = cleverapps.dataLoader.load(DataLoader.TYPES.PLAY_SESSION);
    if (!info) {
        info = {};
    }

    this.data = info;
};

PlaySession.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.PLAY_SESSION, this.data);
};

PlaySession.prototype.getInfo = function () {
    return this.data;
};

PlaySession.prototype.updateInfo = function (serverData) {
    this.data = serverData || {};
    this.save();
};

PlaySession.prototype.endSession = function () {
    const data = this.data;
    PlaySession.EndSessionListeners.forEach((listener) => {
        listener(data);
    });

    this.data = {};
    this.save();
};

PlaySession.prototype.inc = function (key, value, field) {
    if (value === undefined) {
        value = 1;
    }
    let oldValue = this.data[key];
    if (oldValue === undefined) {
        oldValue = 0;
    }

    if (field) {
        oldValue = oldValue || {};
        oldValue[field] = oldValue[field] ? oldValue[field] + value : value;
    }
    const newValue = field ? oldValue : oldValue + value;
    this.set(key, newValue);
};

PlaySession.prototype.set = function (key, value, field) {
    if (field !== undefined) {
        this.data[key] = this.data[key] || {};
        this.data[key][field] = value;
    } else {
        this.data[key] = value;
    }

    this.save();
};

PlaySession.prototype.get = function (key) {
    return this.data[key];
};

PlaySession.prototype.notifyActive = cleverapps.throttle(cleverapps.parseInterval("1 minute"), function () {
    this.inc(cleverapps.EVENTS.STATS.ACTIVE_TIME);

    const location = cleverapps.meta.getSelectedLocation();
    if (location.isExpedition()) {
        this.inc("expActive", 1, location.locationId);
    }

    this.onNotifyActiveListener();
});

PlaySession.prototype.reportNewUser = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.REGISTERED);
    cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.STATS.REGISTERED);

    const channel = cleverapps.user.channel;
    if (!channel) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CHANNEL + channel);
    cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.CHANNEL_OS + channel}_${connector.info.os}`);

    cleverapps.country.whenReady((country) => {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.CHANNEL_COUNTRY + channel}_${country}`);
    });

    cleverapps.whenAllInitialized(() => {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CHANNEL_APP_INITIALIZE + channel);
    });

    this.onNotifyActiveListener = function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CHANNEL_ACTIVE_TIME + channel);
    };

    if (connector.platform.oneOf(connector.CLEVERAPPS) && channel.includes("goo")) {
        cleverapps.consoleStream.enable();
    }

    cleverapps.info.reportIP();
};

PlaySession.AbtestReporter = function (data) {
    let locationId;

    for (locationId in data.expActive) {
        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.STATS.ACTIVE_TIME + locationId, { value: data.expActive[locationId], expedition: locationId });
        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.STATS.ACTIVE_TIME_DAU + locationId, { expedition: locationId });
    }

    const activeTime = data[cleverapps.EVENTS.STATS.ACTIVE_TIME];
    if (activeTime) {
        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.STATS.ACTIVE_TIME, { value: activeTime, expedition: "main" });
        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.STATS.ACTIVE_TIME_DAU, { expedition: "main" });
    }

    cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.STATS.DAU, { expedition: "main" });

    for (locationId in data[cleverapps.EVENTS.LOCATION.PLAYED]) {
        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.LOCATION.PLAYED + locationId, { expedition: locationId });
    }
};

PlaySession.ActiveTimeReporter = function (data) {
    const activeTime = data[cleverapps.EVENTS.STATS.ACTIVE_TIME] || 0;

    const days = cleverapps.user.getDaysSinceRegistration();
    const group = days <= 3 ? "new" : "old";

    cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.ACTIVE_TIME}_${group}`, { value: activeTime });
    cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.ACTIVE_TIME_DAU}_${group}`);
};

PlaySession.SettingsReporter = function () {
    cleverapps.whenAllInitialized(() => {
        const webpSupport = Boolean(cleverapps.config.webpSupport);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.RESOLUTION + cleverapps.resolution.resolutionName);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.WEBP + webpSupport);

        if (connector.platform.oneOf(connector.WECHAT)) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.OS + connector.info.os);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PLATFORM + connector.info.platform);
        }

        if (connector.info.androidVersion) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.ANDROID_VERSION + connector.info.androidVersion);
        }
    });
};

PlaySession.RewardedReporter = function (data) {
    if (data[cleverapps.EVENTS.STATS.REWARDED_AVAILABLE_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.REWARDED_AVAILABLE_DAU);
    }

    if (data[cleverapps.EVENTS.STATS.REWARDED_NOADS_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.REWARDED_NOADS_DAU);
    }

    if (data[cleverapps.EVENTS.STATS.REWARDED_ERROR_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.REWARDED_ERROR_DAU);
    }

    if (data[cleverapps.EVENTS.STATS.REWARDED_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.REWARDED_DAU);
    }

    if (data[cleverapps.EVENTS.STATS.REWARDED_LOADED_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.REWARDED_LOADED_DAU);
    }

    if (data[cleverapps.EVENTS.ADS.SESSION_FINISH_REWARDED]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.SESSION_FINISH_REWARDED, {
            value: data[cleverapps.EVENTS.ADS.SESSION_FINISH_REWARDED]
        });
    }

    const vipRewardedWatched = data[cleverapps.EVENTS.STATS.VIP_REWARDED_WATCHED];
    if (vipRewardedWatched) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.VIP_REWARDED_WATCHED, { value: vipRewardedWatched });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.VIP_REWARDED_DAU);
    }
};

PlaySession.BannerAdReporter = function (data) {
    if (data[cleverapps.EVENTS.STATS.BANNER.DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BANNER.DAU);
    }

    const impressionsEvent = `${cleverapps.EVENTS.ADS.TYPE}banner`;
    if (data[impressionsEvent]) {
        cleverapps.eventLogger.logEvent(impressionsEvent, {
            value: data[impressionsEvent],
            metricProcessor: true
        });
    }

    const costEvent = `${cleverapps.EVENTS.ADS.TYPE_COST}banner`;
    if (data[costEvent]) {
        cleverapps.eventLogger.logEvent(costEvent, { value: data[costEvent] });
    }
};

PlaySession.DailyTasksReporter = function (data) {
    if (!cleverapps.dailyTasks || !cleverapps.dailyTasks.isAvailable()) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SESSION_DAILY_TASK_AVAILABLE);

    if (data[cleverapps.EVENTS.SESSION_DAILY_TASK_OPEN_WINDOW]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SESSION_DAILY_TASK_OPEN_WINDOW);
    }

    if (cleverapps.dailyTasks.getFinishedTasks().length > 0) {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.SESSION_DAILY_TASK_FINISH}_${cleverapps.dailyTasks.getFinishedTasks().length}`);
    }

    const amount = data[cleverapps.EVENTS.SESSION_DAILY_TASK_REWARD];
    if (amount) {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.SESSION_DAILY_TASK_REWARD}_${amount}`);
    }
};

PlaySession.CupsReporter = function (data) {
    Object.keys(data).forEach((event) => {
        if (!data[event]) {
            return;
        }

        if (event.indexOf(cleverapps.EVENTS.CUP_DAU) === 0 || event.indexOf(cleverapps.EVENTS.CUP_PLAYERS) === 0) {
            cleverapps.eventLogger.logEvent(event);
        }
    });
};

PlaySession.MiniGameReporter = function (data) {
    Object.keys(data).forEach((event) => {
        if (event.indexOf(cleverapps.EVENTS.MINIGAME_FINISH) === 0 && data[event]) {
            cleverapps.eventLogger.logEvent(`${event}_dau`);
        }
    });
};

PlaySession.MissionReporter = function (data) {
    Object.keys(data).forEach((event) => {
        if (event.indexOf(cleverapps.EVENTS.MISSION_DAU) === 0 && data[event]) {
            cleverapps.eventLogger.logEvent(event);
        }
    });
};

PlaySession.OfferReporter = function (data) {
    Object.keys(data).forEach((event) => {
        if (event.indexOf(cleverapps.EVENTS.OFFER_DAU) === 0 && data[event]) {
            cleverapps.eventLogger.logEvent(event);
        }
    });
};

PlaySession.BonusWorldReporter = function (data) {
    if (data[cleverapps.EVENTS.BONUS_WORLD_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BONUS_WORLD_DAU);
    }

    if (data[cleverapps.EVENTS.BONUS_WORLD_LEVEL_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BONUS_WORLD_LEVEL_DAU);
    }
};

PlaySession.GrowthFundReporter = function (data) {
    if (data[cleverapps.EVENTS.FUND_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FUND_DAU);
    }
};

PlaySession.ExpeditionReporter = function (data) {
    let locationId;

    for (locationId in data[cleverapps.EVENTS.LOCATION.ACTIVE]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOCATION.ACTIVE + locationId);
    }

    for (locationId in data[cleverapps.EVENTS.LOCATION.PLAYED]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOCATION.PLAYED + locationId);
    }
};

PlaySession.DebugReporter = function (data) {
    for (const value in data[cleverapps.EVENTS.DEBUG.MERGE.UNKNOWN_UNIT_DAU]) {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.DEBUG.MERGE.UNKNOWN_UNIT_DAU}-${value}`);
    }

    if (data[cleverapps.EVENTS.DEBUG.UNKNOWN_MISSION_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.UNKNOWN_MISSION_DAU);
    }

    for (value in data[cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2]) {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2}-${value}`);
    }
};

PlaySession.ClansReporter = function (data) {
    if (data[cleverapps.EVENTS.CLANS_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.CLANS_DAU);
    }
};

PlaySession.UnitsReporter = function (data) {
    const unitsAmount = data[cleverapps.EVENTS.UNITS_AMOUNT];
    if (unitsAmount) {
        for (const key in unitsAmount.amount) {
            cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.UNITS_AMOUNT}_${key}_${unitsAmount.level}`, {
                value: unitsAmount.amount[key]
            });
        }

        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.UNITS_AMOUNT_DAU}_${unitsAmount.level}`);
    }

    let level;

    if (data[cleverapps.EVENTS.EARNED_MAGICPLANT]) {
        for (level in data[cleverapps.EVENTS.EARNED_MAGICPLANT]) {
            cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.EARNED_MAGICPLANT}_${level}`, {
                value: data[cleverapps.EVENTS.EARNED_MAGICPLANT][level]
            });
        }
    }

    const events = [cleverapps.EVENTS.EARNED_COINSPLANT, cleverapps.EVENTS.EARNED_RUBIESPLANT,
        cleverapps.EVENTS.EARNED_ENERGYPLANT];

    events.forEach((event) => {
        const stages = [0, 1, 2];
        for (let stage = 0; stage < stages.length; stage++) {
            if (data[`${event}_${stage}`]) {
                for (level in data[`${event}_${stage}`]) {
                    cleverapps.eventLogger.logEvent(`${event}_${stage}_${level}`, {
                        value: data[`${event}_${stage}`][level]
                    });
                }
            }
        }
    });

    if (data[cleverapps.EVENTS.CASTLE_PRIZES]) {
        for (level in data[cleverapps.EVENTS.CASTLE_PRIZES]) {
            for (const code in data[cleverapps.EVENTS.CASTLE_PRIZES][level]) {
                cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.CASTLE_PRIZES}_${code}_${level}`, {
                    value: data[cleverapps.EVENTS.CASTLE_PRIZES][level][code]
                });
            }
        }
    }

    if (data[cleverapps.EVENTS.PET_RATE]) {
        for (const tier in data[cleverapps.EVENTS.PET_RATE]) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PET_RATE + tier.replace("clpet", ""), {
                value: data[cleverapps.EVENTS.PET_RATE][tier]
            });
        }
    }
};

PlaySession.LocalPushesReporter = function () {
    if (cleverapps.localPushes.permitted) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PUSHES.PERMITTED_DAU);
    }
};

PlaySession.ContextLostReporter = function (data) {
    if (data[cleverapps.EVENTS.WEBGL.CONTEXT_LOST]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_DAU);
    }

    if (data[cleverapps.EVENTS.WEBGL.CONTEXT_LOST] >= 2) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_MULTIPLE);
    }

    if (data[cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER_DAU);
    }

    if (data[cleverapps.EVENTS.WEBGL.CONTEXT_LOST_INACTIVE]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_INACTIVE_DAU);
    }

    if (data[cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER_INACTIVE]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER_INACTIVE_DAU);
    }
};

PlaySession.OkSessionReporter = function (data) {
    if (data[cleverapps.EVENTS.DEBUG.OK_SESSION_EXPIRED]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.OK_SESSION_EXPIRED);
    }

    if (data[cleverapps.EVENTS.DEBUG.OK_SESSION_RESTORED]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.OK_SESSION_RESTORED);
    }
};

PlaySession.MetaLocationReporter = function (data) {
    let location = data[cleverapps.EVENTS.META_LOCATION];
    if (location === undefined) {
        // TMP compat
        location = cleverapps.user.episode;
    }

    cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.META_LOCATION}_${location}`);
};

PlaySession.FpsReporter = function (data) {
    if (data[cleverapps.EVENTS.STATS.FPS_RATE] && data[cleverapps.EVENTS.STATS.FPS_COUNT]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.FPS_RATE, {
            value: data[cleverapps.EVENTS.STATS.FPS_RATE]
        });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.FPS_COUNT, {
            value: data[cleverapps.EVENTS.STATS.FPS_COUNT]
        });
    }
};

PlaySession.AdBlockReporter = function (data) {
    if (data[cleverapps.EVENTS.ADS.AD_BLOCK_WINDOW_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.AD_BLOCK_WINDOW_DAU);
    }
};

PlaySession.HungerReporter = function (data) {
    if (data[cleverapps.EVENTS.STATS.HUNGRY_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.HUNGRY_DAU);
    }
};

PlaySession.CrmReporter = function (data) {
    if (data[cleverapps.EVENTS.STATS.CRM.AVAILABLE_DAU]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.AVAILABLE_DAU);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.UNANSWERED]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.UNANSWERED);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.REPLY]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.REPLY);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.RESOLVED]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.RESOLVED);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.LATE_REPLY]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.LATE_REPLY);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.AVAILABLE_DAU_PAYER]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.AVAILABLE_DAU_PAYER);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.UNANSWERED_PAYER]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.UNANSWERED_PAYER);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.REPLY_PAYER]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.REPLY_PAYER);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.RESOLVED_PAYER]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.RESOLVED_PAYER);
    }

    if (data[cleverapps.EVENTS.STATS.CRM.LATE_REPLY_PAYER]) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CRM.LATE_REPLY_PAYER);
    }
};

PlaySession.EndSessionListeners = [
    PlaySession.RewardedReporter,
    PlaySession.BannerAdReporter,
    PlaySession.DailyTasksReporter,
    PlaySession.SettingsReporter,
    PlaySession.CupsReporter,
    PlaySession.MiniGameReporter,
    PlaySession.MissionReporter,
    PlaySession.OfferReporter,
    PlaySession.BonusWorldReporter,
    PlaySession.GrowthFundReporter,
    PlaySession.ExpeditionReporter,
    PlaySession.DebugReporter,
    PlaySession.ClansReporter,
    PlaySession.UnitsReporter,
    PlaySession.ActiveTimeReporter,
    PlaySession.AbtestReporter,
    PlaySession.LocalPushesReporter,
    PlaySession.ContextLostReporter,
    PlaySession.OkSessionReporter,
    PlaySession.MetaLocationReporter,
    PlaySession.FpsReporter,
    PlaySession.AdBlockReporter,
    PlaySession.HungerReporter,
    PlaySession.CrmReporter
];

PlaySession.ACTIVE_TIME_INTERVAL = "1 minute";
