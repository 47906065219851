/**
 * Created by razial on 16.01.2025.
 */

const FarmLogic = function () {

};

FarmLogic.prototype.reset = function () {

};

FarmLogic.prototype.isLocationAvailable = function (locationId) {
    return locationId === 0;
};

FarmLogic.prototype.getNextLocation = function () {

};

FarmLogic.prototype.getMainLocation = function () {
    return 0;
};

FarmLogic.prototype.getLocationClass = function () {
    return FarmLocation;
};

FarmLogic.prototype.listActiveLocations = function () {
    return [0];
};

FarmLogic.prototype.isCompleted = function () {
    return false;
};

FarmLogic.prototype.processChatMessage = function (message) {
    if (message.metha.farm) {
        const farm = cleverapps.meta.getSelectedLocation();
        farm.reset();

        const newData = FarmLocation.GetBuildingProgress(message.metha.farm.building, message.metha.farm.quest);
        farm.load(newData);
        farm.save();
    }
};
