/**
 * Created by razial on 19.12.2024.
 */

const ShortMetaAdapter = function () { 

};

ShortMetaAdapter.prototype.load = function () {
    const data = cleverapps.dataLoader.load(DataLoader.TYPES.META)
        || cleverapps.dataLoader.load(DataLoader.TYPES.SHORTMETA)
        || cleverapps.dataLoader.load(DataLoader.TYPES.KNOCKOUT)
        || {};

    this.updateInfo(data);
};

ShortMetaAdapter.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

ShortMetaAdapter.prototype.updateInfo = function (data) {
    cleverapps.meta.logic.currentLocationId = this.calcLocationAndProgress().location || 0;

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.updateData(data);
    }
};

ShortMetaAdapter.prototype.getInfo = function () {
    const data = {};

    if (cleverapps.knockoutGame) {
        Object.assign(data, cleverapps.knockoutGame.getData());
    }

    return data;
};

ShortMetaAdapter.prototype.loadLocation = function () {
    return { progress: this.calcLocationAndProgress().progress || 0 };
};

ShortMetaAdapter.prototype.saveLocation = function () {

};

ShortMetaAdapter.prototype.calcLocationAndProgress = function () {
    const total = levels.user.episode * Level.LEVELS_PER_EPISODE + levels.user.level + levels.user.progress;
    return {
        location: Math.floor(total / ShortMetaLocation.LEVELS_PER_LOCATION),
        progress: total % ShortMetaLocation.LEVELS_PER_LOCATION
    };
};

ShortMetaAdapter.prototype.reset = function () {

};
