/**
 * Created by razial on 14.01.2025.
 */

const MergeMetaAdapter = function () {

};

MergeMetaAdapter.prototype.load = function () {
    const data = cleverapps.dataLoader.load(DataLoader.TYPES.META);
    this.updateInfo(data);
};

MergeMetaAdapter.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

MergeMetaAdapter.prototype.updateInfo = function (serverData) {
    cleverapps.meta.logic.updateInfo(serverData);
};

MergeMetaAdapter.prototype.getInfo = function () {
    return cleverapps.meta.logic.getInfo();
};

MergeMetaAdapter.prototype.loadLocation = function () {
    return {};
};

MergeMetaAdapter.prototype.saveLocation = function () {

};

MergeMetaAdapter.prototype.reset = function () {

};

MergeMetaAdapter.prototype.startLocation = function (locationId) {
    const page = MergeLogic.findPageById(locationId);
    if (!page || page.missionType === undefined) {
        return;
    }

    cleverapps.missionManager.add(page.missionType);
    cleverapps.missionManager.save();
    cleverapps.missionManager.runManualMissions();
};

MergeMetaAdapter.prototype.finishLocation = function (locationId) {
    const page = MergeLogic.findPageById(locationId);
    if (!page || page.missionType === undefined) {
        return;
    }

    const mission = cleverapps.missionManager.findByType(page.missionType);
    if (mission) {
        cleverapps.missionManager.remove(mission);
    }

    cleverapps.missionManager.updateOldMissions(page.missionType, Date.now());
};

MergeMetaAdapter.prototype.setLocationFromMissionInfo = function (missionInfo) {
    const missionType = Mission.OLD_VERSIONS[missionInfo.type] || missionInfo.type;
    const missionData = Missions[missionType];
    if (!missionData || !missionData.id) {
        return;
    }

    const slot = this.semaphoreToSlot(missionData.semaphore);
    if (slot === undefined) {
        return;
    }

    const version = cleverapps.castType(Mission.ParseTypeVersion(missionInfo.type));

    const locationInfo = cleverapps.meta.logic.locations[missionData.id];
    if (locationInfo && locationInfo.version > version) {
        return;
    }

    cleverapps.meta.logic._setLocation(missionData.id, {
        version,
        slot,
        started: missionInfo.started,
        finished: missionInfo.lastRemoved
    });

    cleverapps.meta.needSaveAfterMigration = true;
};

MergeMetaAdapter.prototype.updatePermanentLocations = function () {
    cleverapps.meta.logic.listAvailableLocations().forEach((locationId) => {
        const page = MergeLogic.findPageById(locationId);
        if (!cleverapps.meta.logic.locations[locationId] && page.permanent && cleverapps.user.checkAvailable(page.available)) {
            cleverapps.meta.logic._setLocation(locationId, {
                version: page.version,
                slot: page.slot
            });
            cleverapps.meta.needSaveAfterMigration = true;
        }
    });
};

MergeMetaAdapter.prototype.semaphoreToSlot = function (semaphore) {
    const slotsBySemaphore = {};
    slotsBySemaphore[Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1] = Meta.EXPEDITION_SLOT1;
    slotsBySemaphore[Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT2] = Meta.EXPEDITION_SLOT2;
    slotsBySemaphore[Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4] = Meta.EXPEDITION_SLOT4;
    return slotsBySemaphore[semaphore];
};