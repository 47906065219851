/**
 * Created by slava on 02.02.17.
 */

cleverapps.User = function (isNewUser) {
    cleverapps.EventEmitter.call(this);

    this.episode = 0;
    this.level = 0;
    this.progress = 0;
    this.progressCompare = 0;
    this.gold = 0;
    this.soft = 0;
    this.registered = Date.now();
    this.visited = Date.now();

    this.onCantTakeGold = function () {
        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_DISABLED) {
            cleverapps.notification.create("NotEnoughCoins");
        } else {
            cleverapps.focusManager.display({
                stack: true,
                focus: "ShopWindow",
                control: "MenuBarGoldItem",
                action: function (f) {
                    new HardCurrencyShopWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    };

    this.onLeagueChangeListener = function () {};

    if (!isNewUser) {
        const info = cleverapps.dataLoader.load(DataLoader.TYPES.ID);
        this.loadData(info);

        this.lastReportDAU = this.getDaysSinceRegistration(info.visited);
    }

    this.save();

    this.updateIDFA();

    cleverapps.whenAllInitialized(this.reportDAU.bind(this));
    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, () => {
        if (this.visited < Date.now() - cleverapps.parseInterval(cleverapps.User.ON_SHOW_CHANGE_VISITED)) {
            this.updateVisited();
            cleverapps.whenAllInitialized(this.reportDAU.bind(this));
        }
    });
};

cleverapps.User.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.User.prototype.constructor = cleverapps.User;

cleverapps.User.prototype.updateVisited = function () {
    this.visited = Date.now();
    this.save();
};

cleverapps.User.prototype.incProgressCompare = function (amount, onSaveUser) {
    amount = amount || 1;
    this.progressCompare += amount;
    if (!onSaveUser) {
        this.save();
    }
};

cleverapps.User.prototype.reportDAU = function () {
    const daysSinceRegistration = this.getDaysSinceRegistration();

    if (this.lastReportDAU !== undefined && this.lastReportDAU === daysSinceRegistration) {
        return;
    }

    this.lastReportDAU = daysSinceRegistration;

    cleverapps.playSession.endSession();

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.DAU);

    if (connector.info.platform) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.DAU_PLATFORM + connector.info.platform);
    }

    cleverapps.country.whenReady((country) => {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.DAU_COUNTRY + country);

        if (connector.info.platform && ["US"].includes(country)) {
            cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.DAU_COUNTRY_PLATFORM + country}_${connector.info.platform}`);
        }
    });

    if (connector.info.isNative && connector.social.isLoggedIn()) {
        cleverapps.eventLogger.logEvent(`${connector.social.getName()}_loggedin_dau`);
    }

    if (cleverapps.paymentsHistory.isPayer()) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PAYER_DAU);
    }

    if (daysSinceRegistration < 1) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.NEW_USERS_DAU, true);
        cleverapps.conversionManager.logDay1Dau();
    }

    if (daysSinceRegistration > 0) {
        this.reportRetention();
        this.reportCustomDau();
    }

    this.reportDauVersion();
};

cleverapps.User.prototype.reportDauVersion = function () {
    const appVersion = cleverapps.config.version.split(".").map((num, index) => {
        if (index === 2) {
            num = cleverapps.padZeroes(num, 3);
        }
        return num;
    }).join("_");

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.DAU_VERSION + appVersion);
};

cleverapps.User.prototype.reportCustomDau = function () {
    const data = cleverapps.dataLoader.load(DataLoader.TYPES.CUSTOM_DAU) || {};
    const lastReportDau30 = data.lastReportDau30 || 0;

    if (lastReportDau30 + cleverapps.parseInterval("30 days") > Date.now()) {
        return;
    }

    const platform = connector.info.platform;
    if (!platform) {
        return;
    }

    cleverapps.country.whenReady((country) => {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.DAU30_COUNTRY_PLATFORM + country}_${platform}`);
    });

    data.lastReportDau30 = Date.now();

    cleverapps.dataLoader.save(DataLoader.TYPES.CUSTOM_DAU, data);
};

cleverapps.User.prototype.reportRetention = function () {
    const daysSinceRegistration = this.getDaysSinceRegistration();

    const retentionEvent = cleverapps.EVENTS.STATS[`RETENTION_${daysSinceRegistration}`];
    if (retentionEvent) {
        cleverapps.eventLogger.logEvent(retentionEvent);

        cleverapps.abExperiments.allLogEvent(retentionEvent);
    }

    cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.VISIT, 1);
};

cleverapps.User.prototype.getDaysSinceRegistration = function (timestamp) {
    return Math.floor(((timestamp || Date.now()) - this.registered) / 1000 / 24 / 60 / 60);
};

cleverapps.User.prototype.loadData = function (info) {
    let userId = info.id;

    this.lastVersion = info.version;
    if (Version.compare(this.lastVersion, "1.300.0") < 0 && connector.platform.oneOf(connector.YANDEX)) {
        console.log(`replace ya id ${userId} -> ${userId.replace(/~/g, "/")}`);
        userId = userId.replace(/~/g, "/");
    }

    if (userId) {
        connector.platform.setUserID(userId);
    }

    this.gold = info.gold || 0;
    this.registered = info.registered;
    this.loggedIn = info.loggedIn;

    if (typeof info.channel === "string") {
        info.channel = info.channel.substring(0, cleverapps.User.MAX_CHANNEL_LENGTH);
    }

    this.channel = info.channel;

    this.lastVisited = info.visited;
    this.visited = Date.now();

    cleverapps.expBySlots[Meta.SLOT_MAIN].load(info.exp);

    this.league = this.calcLeague();
    this.idfa = info.idfa;

    this.progressCompare = info.progressCompare || 0;
    this.soft = info.soft || 0;
};

cleverapps.User.prototype.isBeginner = function () {
    return this.episode <= 1;
};

cleverapps.User.prototype.isNovice = function () {
    return this.episode <= 3 && this.episode > 1;
};

cleverapps.User.prototype.isFirstSession = function () {
    return this.registered === this.visited;
};

cleverapps.User.prototype.checkAvailable = function () {
    return true;
};

cleverapps.User.prototype.earnHard = function (eventName, value, silent) {
    if (value === 0) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EARN.HARD, { value });
    cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.EARN.HARD, { value });

    if (eventName === undefined) {
        cleverapps.throwAsync("undefined earn hard event");
    }

    cleverapps.eventLogger.logEvent(`earn_hard-${eventName}`, {
        value
    });
    if (eventName === cleverapps.EVENTS.EARN.PURCHASE) {
        cleverapps.abExperiments.allLogEvent(`${cleverapps.EVENTS.EARN.HARD}-${eventName}`, { value });
    }

    if (cleverapps.paymentsHistory.isPayer()) {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.EARN.PAYER_HARD}_${eventName}`, { value });
    }

    this.gold += value;
    this.incProgressCompare(3, true);
    this.save();

    if (!silent) {
        this.onChangeGold();
    }
};

cleverapps.User.prototype.onChangeGold = function () {
    this.trigger("changeHard");
};

cleverapps.User.prototype.canTakeHard = function (gold) {
    return this.gold >= gold;
};

cleverapps.User.prototype.spendHard = function (eventName, value) {
    if (value === 0) {
        return true;
    }

    if (this.canTakeHard(value)) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.SPEND_HARD, value);

        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.SPENT.HARD, { value });

        let spendEvent = `${cleverapps.EVENTS.SPENT.HARD}-`;
        if (cleverapps.meta.getSelectedLocation().isExpedition()) {
            spendEvent += cleverapps.meta.selectedLocationId();
        }
        cleverapps.eventLogger.logEvent(spendEvent + eventName, { value });

        if (cleverapps.paymentsHistory.isPayer()) {
            cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.SPENT.PAYER_HARD}_${eventName}`, { value });
        }

        if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE) {
            cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.SPENT.HARD}-${Game.currentGame.getMode()}`, { value });
        }

        if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.level && Game.currentGame.level.isBonusWorldLevel && Game.currentGame.level.isBonusWorldLevel() || cleverapps.environment.isBonusWorldScene()) {
            const mission = cleverapps.missionManager.findByType(Mission.TYPE_BONUS_WORLD);
            if (mission) {
                cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.SPENT.HARD}-${mission.bonusWorld.type}${eventName}`, { value });
            }
        }

        cleverapps.eventLogger.logEvent(`hard-${eventName}`, { value });

        cleverapps.aims.showTarget(cleverapps.aims.getTarget("hard"));

        this.gold -= value;
        this.incProgressCompare(3, true);
        this.save();

        this.trigger("changeHard");

        return true;
    }
    this.onCantTakeGold(value - this.gold);
    return false;
};

cleverapps.User.prototype.setSoft = function (soft, silent, options) {
    const diff = soft - this.soft;

    if (diff !== 0) {
        this.soft = soft;
        this.incProgressCompare(1, true);
        this.save();

        if (!silent) {
            this.trigger("changeSoft");
        }
    }

    if (diff > 0) {
        const eventName = options && options.event;
        if (eventName === undefined) {
            cleverapps.throwAsync("undefined earn soft event");
        }

        cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.EARN.SOFT, { value: diff });

        cleverapps.eventLogger.logEvent(`earn_soft-${eventName}`, {
            value: diff
        });
        if (eventName === cleverapps.EVENTS.EARN.PURCHASE) {
            cleverapps.abExperiments.allLogEvent(`${cleverapps.EVENTS.EARN.SOFT}-${eventName}`, { value: diff });
        }

        if (cleverapps.meta.getSelectedLocation().isExpedition()) {
            cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.EARN.SOFT}-${cleverapps.meta.selectedLocationId()}`, { value: diff });
        }
    }
};

cleverapps.User.prototype.canTakeSoft = function (soft) {
    return this.soft >= soft;
};

cleverapps.User.prototype.spendSoft = function (eventName, soft, options) {
    options = options || {};
    if (!this.canTakeSoft(soft)) {
        cleverapps.focusManager.display({
            stack: true,
            focus: "ShopWindow",
            control: ["MenuBarGoldItem", "MenuBarCoinsItem"],
            action: function (f) {
                new SoftCurrencyShopWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
        return false;
    }

    this.setSoft(this.soft - soft);

    if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE) {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.SPENT.SOFT}-${Game.currentGame.getMode()}`, { value: soft });
    }

    cleverapps.abExperiments.allLogEvent(cleverapps.EVENTS.SPENT.SOFT, { value: soft });

    let spendEvent = `${cleverapps.EVENTS.SPENT.SOFT}-`;
    if (cleverapps.meta.getSelectedLocation().isExpedition()) {
        spendEvent += cleverapps.meta.selectedLocationId();
    }
    cleverapps.eventLogger.logEvent(spendEvent + eventName, { value: soft });

    cleverapps.eventBus.trigger("taskEvent", DailyTasks.SPEND_SOFT, soft);
    cleverapps.audio.playSound(bundles.main.urls.shop_buy_effect);

    const reward = new RewardsList({
        mission: { amount: soft, missionType: Mission.TYPE_SOFTFEAST }
    });
    reward.receiveRewards();

    const location = cleverapps.meta.getSelectedLocation();
    if (location.isMain()) {
        reward.collectRewardsAnimation(options.source, {
            withoutDelta: true
        });
    }

    return true;
};

cleverapps.User.prototype.setLoggedIn = function (loggedIn) {
    this.loggedIn = loggedIn;
};

cleverapps.User.prototype.getSaveInfo = function () {
    return {
        id: connector.platform.getUserID(),
        gold: this.gold,
        soft: this.soft,
        registered: this.registered,
        visited: this.visited,
        channel: this.channel,
        exp: cleverapps.expBySlots[Meta.SLOT_MAIN].getExp(),
        idfa: this.idfa,
        progressCompare: this.progressCompare,
        version: cleverapps.config.version,
        loggedIn: this.loggedIn
    };
};

cleverapps.User.prototype.afterSync = function () {
    this.league = this.calcLeague();
};

cleverapps.User.prototype.save = function (updateServer) {
    if (updateServer === false) {
        this.afterSync();
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.ID, this.getSaveInfo());

    if (updateServer !== false) {
        cleverapps.synchronizer.addUpdateTask("users");
    }
};

cleverapps.User.prototype.setRandomBoostersReward = function (reward) {
    if (!reward.randomBoosters) {
        return reward;
    }

    reward.boosters = {};
    for (let i = 0; i < reward.randomBoosters.amount; i++) {
        const type = cleverapps.Random.choose(reward.randomBoosters.types);
        reward.boosters[type] = reward.boosters[type] ? reward.boosters[type] + 1 : 1;
    }
    delete reward.randomBoosters;
    return reward;
};

cleverapps.User.prototype.prepareRewardByRich = function (reward, minGold) {
    if (!reward.hard && !reward.boosters && !reward.randomBoosters) {
        return reward;
    }

    reward = cleverapps.clone(reward, true);
    reward = this.setRandomBoostersReward(reward);

    const lastPaymentTime = cleverapps.paymentsHistory.getLastPaymentTime();
    if (lastPaymentTime && lastPaymentTime > Date.now() - cleverapps.parseInterval("1 day")) {
        return reward;
    }

    let requireBoobyPrize = false;

    if (reward.hard) {
        let powerDecrease = true;
        if (lastPaymentTime && lastPaymentTime > Date.now() - cleverapps.parseInterval("3 day")) {
            powerDecrease = false;
        }

        let goldCoef = 1;
        if (this.gold >= 1000 && powerDecrease) {
            goldCoef = 0.1;
            requireBoobyPrize = true;
        } else if (this.gold >= 600) {
            requireBoobyPrize = true;
            goldCoef = 0.25;
        } else if (this.gold >= 300) {
            goldCoef = 0.5;
        }

        reward.hard = Math.max(Math.ceil(reward.hard * goldCoef / 5) * 5, minGold || 0);
    }

    if (requireBoobyPrize) {
        let boobyPrizes = [];

        if (cleverapps.config.type === "match3") {
            if (cleverapps.starChest && cleverapps.starChest.isEnabled()) {
                boobyPrizes = [{
                    stars: 3,
                    exp: 1
                }, {
                    stars: 3
                }, {
                    stars: 2,
                    exp: 1
                }, {
                    exp: 2
                }];
            } else {
                boobyPrizes = [{
                    exp: 1
                }, {
                    exp: 1
                }, {
                    exp: 2
                }];
            }
        }

        if (!reward.unlimitedLives && cleverapps.lives) {
            boobyPrizes = boobyPrizes.concat([{
                unlimitedLives: "10 minutes"
            }, {
                unlimitedLives: "15 minutes"
            }, {
                unlimitedLives: "30 minutes"
            }]);
        }

        if (boobyPrizes.length > 0) {
            const booby = cleverapps.Random.choose(boobyPrizes);
            for (const key in booby) {
                if (!reward[key]) {
                    reward[key] = booby[key];
                } else {
                    reward[key] += booby[key];
                }
            }
        }
    }

    return reward;
};

cleverapps.User.prototype.isReturnedPlayer = function () {
    return this.lastVisited && (this.lastVisited + cleverapps.parseInterval("2 week")) < Date.now();
};

cleverapps.User.prototype.calcChannel = function (callback) {
    const providers = [
        function (callback) {
            connector.platform.parsePayload((data) => {
                callback(data && data.channel);
            });
        },

        function (callback) {
            const platform = connector.info.platform;
            const available = platform && connector.platform.oneOf(connector.INSTANT);

            if (!available) {
                callback();
                return;
            }

            cleverapps.country.whenReady((country) => {
                const channel = ["US"].includes(country) ? `#${country}_${platform}` : "";
                callback(channel);
            });
        },

        function (callback) {
            connector.platform.calcPromo(callback);
        }
    ];

    const actions = providers.map((provider) => function (f, stop) {
        provider((channel) => {
            if (channel) {
                this.setChannel(channel);
                stop();
            } else {
                f();
            }
        });
    }.bind(this));
    
    const player = new ActionPlayer(actions);
    player.onComplete(callback);
    player.play();
};

cleverapps.User.prototype.setChannel = function (channel) {
    channel = channel.substring(0, cleverapps.User.MAX_CHANNEL_LENGTH);

    if (this.channel !== channel) {
        this.channel = channel;
        this.save();
    }
};

cleverapps.User.prototype.updateIDFA = function () {
    const idfa = connector.info.deviceID;

    if (!idfa || this.idfa === idfa) {
        return;
    }

    console.log(`user set idfa - ${this.idfa}`);
    this.idfa = idfa;
    cleverapps.dataLoader.save(DataLoader.TYPES.ID, this.getSaveInfo());

    cleverapps.RestClient.post(
        `/users/idfa/${connector.platform.getUserID()}`,
        { idfa: this.idfa }
    );
};

cleverapps.User.prototype.initializeNewPlayerGold = function () {
    this.gold = 100;
    if (cleverapps.config.type === "merge") {
        this.gold = 15;
    }
    if (cleverapps.config.type === "battlefield") {
        this.gold = 50;
    }
    if (cleverapps.flags.lowMonetization) {
        this.gold = 0;
    }
};

cleverapps.User.prototype.initializeNewPlayerSoft = function () {
    this.soft = 0;
    if (cleverapps.config.type === "merge") {
        this.soft = 100;
    }
    if (cleverapps.config.type === "battlefield") {
        this.soft = 30;
    }
};

cleverapps.User.prototype.initializeNewPlayer = function () {
    this.initializeNewPlayerGold();
    this.initializeNewPlayerSoft();

    cleverapps.expBySlots[Meta.SLOT_MAIN].load(0);
    this.league = this.calcLeague();

    this.save();
};

cleverapps.User.prototype.calcLeague = function () {
    let league = 0;
    if (!cleverapps.config.leagues) {
        return league;
    }
    while (league < cleverapps.config.leagues.length && cleverapps.config.leagues[league] && this.getHumanReadableNumber() + (this.progress || 0) > cleverapps.config.leagues[league]) {
        league++;
    }
    return league;
};

cleverapps.User.ON_SHOW_CHANGE_VISITED = "1 hour";

cleverapps.User.SOFT_CURRENCY = 0;
cleverapps.User.HARD_CURRENCY = 1;
cleverapps.User.HARD_TO_SOFT = 4.17;

cleverapps.User.MAX_CHANNEL_LENGTH = 20;
