/**
 * Created by razial on 25.12.2024.
 */

const HomefixAdapter = function () {
    this.locations = {};
};

HomefixAdapter.prototype.load = function () {
    let data = cleverapps.dataLoader.load(DataLoader.TYPES.META);

    if (!data) {
        data = cleverapps.dataLoader.load(DataLoader.TYPES.HOMEFIX);
    }

    this.updateInfo(data);
};

HomefixAdapter.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

HomefixAdapter.prototype.updateInfo = function (data) {
    data = data || {};

    cleverapps.meta.logic.currentLocationId = data.current || 0;
    cleverapps.meta.logic.stars = data.stars || 0;

    this.locations = data.locations || {};

    // backward compatability used in home controller
    if (!data.locations && data.oldItems) {
        this.oldItems = data.oldItems;
    }
};

HomefixAdapter.prototype.getInfo = function () {
    return {
        current: cleverapps.meta.logic.currentLocationId,
        stars: cleverapps.meta.logic.stars,
        locations: this.locations
    };
};

HomefixAdapter.prototype.loadLocation = function (locationId) {
    return this.locations[locationId] || {};
};

HomefixAdapter.prototype.saveLocation = function (locationId, data) {
    this.locations[locationId] = data;
    cleverapps.meta.save();
};

HomefixAdapter.prototype.reset = function () {

};