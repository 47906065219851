/**
 * Created by andrey on 15.03.19.
 */

const PagesViewParallax = function (styles, parent, direction) {
    this.parallaxBackgrounds = [];
    this.direction = direction;

    const layers = styles && styles.layers || 1;
    for (let layer = 0; layer < layers; layer++) {
        const bg = bundles.main.urls[`episodes_bg_jpg_${layer}`];

        if (!bg) {
            continue;
        }

        const getSize = function () {
            const bgSize = cleverapps.resolution.getBgSize();
            let width = styles.width === "scene" ? bgSize.width : styles.width;
            let height = styles.height === "scene" ? bgSize.height : styles.height;

            if (styles.patternSize) {
                if (this.direction === cleverapps.UI.HORIZONTAL) {
                    width = Math.ceil(bgSize.width / styles.patternSize.width) * styles.patternSize.width;
                } else {
                    height = Math.ceil(bgSize.height / styles.patternSize.height) * styles.patternSize.height;
                }
            }

            return cc.size(width, height);
        }.bind(this);

        const parallaxBg = new ParallaxBackground(styles.speed[layer], this.direction, (() => {
            const node = new cc.Node();
            let sprite;

            if (bg) {
                sprite = cleverapps.UI.createPatternSprite(bg, getSize());
                this.parallaxBackgrounds.push(sprite);
                node.addChild(sprite);
            }

            node.updateSize = function () {
                const size = getSize();
                node.setContentSize2(size);

                if (sprite) {
                    if (typeof gl !== "undefined" && sprite.getTexture()) {
                        const rect = sprite.getTextureRect();
                        rect.width = size.width;
                        rect.height = size.height;
                        sprite.setTextureRect(rect);
                    }

                    sprite.setPositionRound(size.width / 2, size.height / 2);
                }
            };

            node.updateSize();
            node.setAnchorPoint(0.5, 0.5);

            return node;
        }));
        parallaxBg.setLocalZOrder(styles.zIndex[layer]);
        parent.addChild(parallaxBg);
    }
    this.updateScale();
};

PagesViewParallax.prototype.updateScale = function () {
    const bgSize = cleverapps.resolution.getBgSize();
    this.parallaxBackgrounds.forEach((background) => {
        const scale = Math.max(bgSize.width / background.width, bgSize.height / background.height);
        background.setScale(scale);
    });
};

const ParallaxBackground = cc.Node.extend({
    ctor: function (ratio, direction, factory) {
        this._super();

        this.ratio = ratio;
        this.direction = direction;

        const frame1 = this.frame1 = new ParallaxFrame(factory(0), direction);
        this.addChild(frame1);
        const frame2 = this.frame2 = new ParallaxFrame(factory(1), direction);
        this.addChild(frame2);

        this.scheduleUpdate();

        this.setupChildren();
    },

    onEnterTransitionDidFinish: function () {
        this._super();

        this._updatePosition();
    },

    update: function (dt) {
        this._super(dt);

        this._updatePosition();
    },

    setupChildren: function () {
        this.frame2.offset = cc.p(this.frame1.width, this.frame1.height);
    },

    _updatePosition: function () {
        this.y = -this.parent.y * (1 - this.ratio);
        this.x = -this.parent.x * (1 - this.ratio);

        this.frame1._updatePosition();
        this.frame2._updatePosition();
    }
});

const ParallaxFrame = cc.Node.extend({
    ctor: function (frame, direction) {
        this._super();

        this.direction = direction;
        this.offset = cc.p(0, 0);

        this.frame = frame;
        this.addChild(frame);

        this.setContentSize2(frame.getContentSize());
        this.setAnchorPoint(0.5, 0.5);

        this.setPositionRound(this.width / 2, this.height / 2);
    },

    updateSize: function () {
        this.setContentSize2(this.frame.getContentSize());
    },

    setupChildren: function () {
        this.setPositionRound(this.width / 2, this.height / 2);
    },

    _updatePosition: function () {
        const winSize = cleverapps.resolution.getBgSize();

        const frame = this.frame;

        const width = frame.width;
        const height = frame.height;

        if (this.direction === cleverapps.UI.HORIZONTAL) {
            const zeroX = -(this.parent.parent.x + this.parent.x);
            frame.x = Math.floor(zeroX / width) * width + width / 2 + this.offset.x;
            frame.y = height / 2;

            frame.visible = (-zeroX + frame.x + width / 2) >= 0 && (-zeroX + frame.x - width / 2) < winSize.width;
        } else {
            const zeroY = -(this.parent.parent.y + this.parent.y) - (height - winSize.height) / 2;
            frame.x = width / 2;
            frame.y = Math.floor(zeroY / height) * height + height / 2 + this.offset.y;

            frame.visible = (-zeroY + frame.y + height / 2) >= 0 && (-zeroY + frame.y - height / 2) < height;
        }
    }
});