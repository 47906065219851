/**
 * Created by vladislav on 17.09.2020
 */

const BackgroundsWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.options = options || {};

        const location = cleverapps.meta.getSelectedLocation();

        this.current = this.options.moveNext ? (location.locationId - 1) : location.locationId;
        this.stars = this.options.moveNext ? location.getRequiredProgress() : location.getProgress();

        const content = this.createContent();

        this._super({
            content,
            title: "BackgroundsWindow.title",
            name: "backgroundswindow",
            foreground: bundles.windows.frames.window_foreground_png,
            closeButton: !this.options.moveNext
        });

        if (this.options.moveNext) {
            this.changeCurrent(this.close.bind(this));
        }

        if (this.options.forceMinimal) {
            this.addForceMinimal();
        }
    },

    addForceMinimal: function () {
        const dialogue = this.dialogue = new MinimalDialogue({
            delay: 0,
            text: "Simple.Force"
        });
        this.addChild(dialogue);
    },

    onShow: function () {
        this._super();

        if (this.dialogue) {
            this.dialogue.display();
        }
    },

    setupChildren: function () {
        this._super();

        if (this.scroll.dir === cleverapps.UI.ScrollView.DIR_HORIZONTAL && this.isVertical()
            || this.scroll.dir === cleverapps.UI.ScrollView.DIR_VERTICAL && !this.isVertical()) {
            this.window.removeFromParent();
            this.onWindowLoaded(this.options);
        }
    },

    isVertical: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
    },

    changeCurrent: function (callback) {
        const current = cleverapps.meta.selectedLocationId();
        const prevBg = this.bgs[current - 1];
        const currentBg = this.bgs[current];
        let nextBg = this.bgs[current + 1];
        if (current === this.bgs.length - 2) {
            nextBg = false;
        }

        this.addTape(prevBg);
        prevBg.tape.setScale(0);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(() => {
                prevBg.progressBar.receivePrize();
            }),
            new cc.DelayTime(3.2),
            new cc.CallFunc(() => {
                cleverapps.audio.playSound(bundles.simple.urls.opening_lock_effect);
            }),
            new cc.DelayTime(0.9),
            new cc.CallFunc(() => {
                if (currentBg.name) {
                    currentBg.name.setFont(cleverapps.styles.FONTS.BACKGROUNDS_WINDOW_NAME_TEXT);
                }

                callback();
            })
        ));

        currentBg.lock.runAction(new cc.Sequence(
            new cc.DelayTime(2.9),
            new cc.ScaleTo(0.4, currentBg.lock.scale * 1.3).easing(cc.easeInOut(3)),
            new cc.ScaleTo(0.4, 0).easing(cc.easeIn(3)),
            new cc.RemoveSelf()
        ));

        prevBg.progressBar.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.ScaleTo(0.3, 1.2 * prevBg.progressBar.baseScale).easing(cc.easeOut(2)),
            new cc.CallFunc(() => {
                cleverapps.audio.playSound(bundles.simple.urls.simple_complete_bg_effect);
            }),
            new cc.ScaleTo(0.3, 0).easing(cc.easeIn(2))
        ));

        prevBg.tape.runAction(new cc.Sequence(
            new cc.DelayTime(2.3),
            new cc.ScaleTo(0.5, 1.0).easing(cc.easeBackInOut(0.6))
        ));

        this.scroll.runAction(new cc.Sequence(
            new cc.DelayTime(2.8),
            new cc.ScrollAction(currentBg, {
                duration: 1.4
            }).easing(cc.easeBackInOut())
        ));

        currentBg.layerColor.runAction(new cc.Sequence(
            new cc.DelayTime(3.5),
            new cc.FadeOut(0.5)
        ));

        if (nextBg) {
            nextBg.closedImage.runAction(new cc.Sequence(
                new cc.DelayTime(4.7),
                new cc.CallFunc(() => {
                    if (nextBg) {
                        this.addLayerColor(nextBg);
                        nextBg.layerColor.setOpacity(150);
                    }
                })
            ));
        }
    },

    createContent: function () {
        const styles = cleverapps.styles.BackgroundsWindow;

        let msg = Messages.get("BackgroundsWindow.text");
        if (this.options.moveNext && ["scramble", "differences"].indexOf(cleverapps.config.name) !== -1) {
            msg = Messages.get("BackgroundsWindow.moveNextText", {
                place: Messages.get(`background_name_${cleverapps.meta.selectedLocationId()}`)
            });
        }

        const text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(this.isVertical() ? styles.text.vertical.width : styles.text.width, 0);
        text.fitTo(undefined, this.isVertical() ? styles.text.vertical.height : styles.text.height);

        this.bgs = [];
        let index = 0;

        let curView = undefined;
        while (bundles[`backgrounds_window_${index}`]) {
            const view = this.createItem(index);
            if (index === this.current) {
                curView = view;
            }
            this.bgs.push(view);

            index++;
        }

        this.bgs.push(this.createItem(index));

        const bgsLayout = new cleverapps.Layout(this.bgs, {
            direction: this.isVertical() ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
            margin: styles.scroll.margin,
            padding: styles.scroll.padding
        });

        const scroll = this.scroll = new cleverapps.UI.ScrollView(bgsLayout);

        if (this.isVertical()) {
            scroll.setContentSize2(bgsLayout.width, styles.scroll.height);
        } else {
            scroll.setContentSize2(styles.scroll.width, bgsLayout.height);
        }

        scroll.scrollTo(curView);

        return new cleverapps.Layout([text, scroll.bg || scroll], {
            direction: cleverapps.UI.VERTICAL,
            margin: this.isVertical() ? styles.verticalMargin : styles.margin,
            padding: styles.padding
        });
    },

    getItemSize: function () {
        const styles = cleverapps.styles.BackgroundsWindow;
        const size = styles.item.size;
        return this.isVertical() ? size.horizontal : size.vertical;
    },

    createItem: function (index) {
        const styles = cleverapps.styles.BackgroundsWindow;

        const container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        const size = this.getItemSize();
        container.setContentSize2(size.width + 2 * styles.item.bg.padding.x, size.height + 2 * styles.item.bg.padding.y);

        const img = index <= this.current ? bundles.simple.frames.frame_gold_png : bundles.simple.frames.frame_silver_png;
        const frame = cleverapps.UI.createScale9Sprite(img, cleverapps.UI.Scale9Rect.TwoPixelXY);
        frame.setContentSize2(container.getContentSize());
        container.addChild(frame);
        frame.setPositionRound(container.width / 2, container.height / 2);
        frame.setLocalZOrder(10);

        if (index < this.current) {
            this.addTape(container);

            this.addBg(container, index);
        } else if (index === this.current) {
            this.addProgressBar(container, this.current, this.stars);

            this.addBg(container, index);
        } else if (cleverapps.meta.isLocationAvailable(index)) {
            const lock = container.lock = new cc.Sprite(bundles.simple.frames.lock_png);
            container.addChild(lock);
            lock.setPositionRound(styles.lock);

            if (index === this.current + 1) {
                this.addBg(container, index);

                this.addLayerColor(container);
                container.layerColor.setOpacity(150);
            } else {
                this.addClosedImage(container);
            }

            if (this.options.moveNext && index === this.current + 2) {
                this.addBg(container, index);
            }
        } else {
            this.addClosedImage(container);

            const comingSoonText = cleverapps.UI.generateTTFText(
                "BackgroundsWindow.ComingSoonTitle",
                cleverapps.styles.FONTS.BACKGROUNDS_WINDOW_NAME_COMING_SOON
            );
            comingSoonText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            comingSoonText.setDimensions(size.width * 0.8, 0);
            container.addChild(comingSoonText);
            comingSoonText.setPositionRound(styles.comingSoon);
        }

        return container;
    },

    addProgressBar: function (container, current, stars) {
        const styles = cleverapps.styles.BackgroundsWindow;

        const progressBar = container.progressBar = new StarsProgressBar({
            width: styles.bar.width,
            stars,
            current,
            withPresent: cleverapps.meta.nextLocationId(cleverapps.meta.selectedLocationId())
        });
        container.addChild(progressBar);
        progressBar.setScale(styles.bar.scale);
        progressBar.baseScale = styles.bar.scale;
        progressBar.setPositionRound(styles.bar);
    },

    addTape: function (container) {
        const styles = cleverapps.styles.BackgroundsWindow;

        const tape = container.tape = new cc.Sprite(bundles.simple.frames.tape_png);
        tape.setLocalZOrder(11);
        container.addChild(tape);
        const pos = styles.name ? styles.tape.withName : styles.tape;
        tape.setPositionRound(pos);

        if (styles.tape.text) {
            const tapeText = cleverapps.UI.generateTTFText(Messages.get("BackgroundsWindow.tapeText").toUpperCase(), cleverapps.styles.FONTS.BACKGROUNDS_WINDOW_TAPE_TEXT);
            tapeText.setLocalZOrder(12);
            tape.addChild(tapeText);
            tapeText.fitTo(styles.tape.text.width);
            tapeText.setPositionRound(styles.tape.text);
        }
    },

    addLayerColor: function (container) {
        const styles = cleverapps.styles.BackgroundsWindow;

        container.layerColor = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
        container.layerColor.setColor(cleverapps.styles.COLORS.CLOSED_BACKGROUND_COLOR);
        container.layerColor.setContentSize2(this.getItemSize());
        container.layerColor.setAnchorPoint(0, 0);
        container.layerColor.setLocalZOrder(-9);
        container.addChild(container.layerColor);
        container.layerColor.setPositionRound(
            container.width / 2 - container.layerColor.width / 2 + styles.layerColor.offsetX,
            container.height / 2 - container.layerColor.height / 2 + styles.layerColor.offsetY
        );
    },

    addClosedImage: function (container) {
        const styles = cleverapps.styles.BackgroundsWindow;
        const image = this.isVertical() ? bundles.simple.frames.closed_bg_png : bundles.simple.frames.closed_bg_vertical_png;

        container.closedImage = new cc.Sprite(image);
        container.closedImage.setLocalZOrder(-9);
        container.addChild(container.closedImage);
        container.closedImage.setPositionRound(styles.item.bg);
    },

    getBgBundleName: function (index) {
        let name = "backgrounds_window_";
        if (!this.isVertical()) {
            name += "vertical_";
        }
        return name + index;
    },

    addBg: function (container, index) {
        const styles = cleverapps.styles.BackgroundsWindow;
        const bundleName = this.getBgBundleName(index);

        const bg = container.bg = new cc.Sprite(bundles[bundleName].urls.background);
        bg.setLocalZOrder(-10);
        container.addChild(bg);
        bg.setPositionRound(styles.item.bg);

        this.addNameText(container, index);
    },

    addNameText: function (container, index) {
        const styles = cleverapps.styles.BackgroundsWindow;
        if (!styles.name) {
            return;
        }

        const font = cleverapps.styles.FONTS.BACKGROUNDS_WINDOW_NAME_TEXT;
        const msg = `background_name_${index}`;
        const name = container.name = cleverapps.UI.generateTTFText(msg, font);
        name.setPositionRound(styles.name);

        const bgImage = bundles.simple.frames.name_background_png;
        if (bgImage) {
            const bg = cleverapps.UI.createScale9Sprite(bgImage, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.addChild(name);
            bg.setPositionRound(styles.name.bg);
            bg.setContentSize2(styles.name.bg.width || container.width, bg.height);
            container.addChild(bg);
            name.fitTo(bg.width * 0.9);
            name.setPositionRound(styles.name);
        } else {
            container.addChild(name, 11);
        }

        if (index === this.current + 1 && styles.lockWithName) {
            container.lock.setPositionRound(styles.lockWithName);
        }
    },

    listBundles: function (options) {
        options = options || {};

        const bundles = [];

        let bgsToLoad = cleverapps.meta.selectedLocationId() + 1;
        if (options.moveNext) {
            bgsToLoad++;
        }

        for (let i = 0; i <= bgsToLoad; i++) {
            bundles.push(this.getBgBundleName(i));
        }

        return bundles;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BACKGROUNDS_WINDOW_TAPE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            color: new cc.Color(168, 28, 54, 255),
            size: 2
        }
    },

    BACKGROUNDS_WINDOW_NAME_COMING_SOON: {
        size: 60,
        color: new cc.Color(255, 232, 72, 255),
        stroke: {
            size: 1,
            color: new cc.Color(198, 90, 22, 255)
        },
        shadow: {
            direction: cc.size(0, -4),
            color: new cc.Color(198, 90, 22, 255),
            blur: 1
        }
    },

    BACKGROUNDS_WINDOW_NAME_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});
cleverapps.overrideColors(cleverapps.styles.COLORS, {
    CLOSED_BACKGROUND_COLOR: new cc.Color(0, 8, 38, 255)
});

cleverapps.styles.BackgroundsWindow = {
    margin: -10,
    verticalMargin: 5,

    padding: {
        bottom: -26,
        right: -14,
        left: -14
    },

    text: {
        width: 1700,
        height: 100,
        vertical: {
            width: 600,
            height: 200
        }
    },

    lock: {
        x: { align: "center" },
        y: { align: "center", dy: 8 }
    },

    tape: {
        x: { align: "right", dx: 16 },
        y: { align: "top", dy: -25 },
        withName: {
            x: { align: "right", dx: 16 },
            y: { align: "top", dy: -120 }
        },
        text: {
            x: 147,
            y: 58,
            width: 220
        }
    },

    layerColor: {
        offsetX: 0,
        offsetY: 0
    },

    comingSoon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    bar: {
        width: 300,
        scale: 0.8,
        x: { align: "center", dx: -25 },
        y: { align: "bottom", dy: 80 }
    },

    item: {
        size: {
            vertical: {
                width: 443,
                height: 633
            },
            horizontal: {
                width: 600,
                height: 350
            }
        },
        bg: {
            padding: {
                x: 13,
                y: 15
            },
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    scroll: {
        x: { align: "center" },
        y: { align: "center", dy: -1 },
        height: 800,
        width: 1800,
        margin: 15,

        padding: {
            x: 35,
            y: 40
        },

        rightHorizontalFrame: {
            x: { align: "right", dx: 2 },
            y: { align: "center" },
            padding: 30
        },

        leftHorizontalFrame: {
            x: { align: "left" },
            y: { align: "center" },
            padding: 30
        }
    },

    present: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -30 },
        width: 200,
        height: 200,
        scale: 1,
        animation: {
            scale: 0.5,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    }
};
