/**
 * Created by razial on 16.01.2025.
 */

const HomefixLogic = function () {
    this.currentLocationId = 0;
    this.stars = 0;
};

HomefixLogic.prototype.reset = function () {
    this.currentLocationId = 0;
    this.stars = 0;
};

HomefixLogic.prototype.isLocationAvailable = function (locationId) {
    return Boolean(bundles[`location_${locationId}`]);
};

HomefixLogic.prototype.recentAvailableLocation = function (locationId) {
    locationId = parseInt(locationId);

    while (locationId >= 0 && !this.isLocationAvailable(locationId)) {
        locationId--;
    }
    return locationId;
};

HomefixLogic.prototype.getNextLocation = function (locationId) {
    return locationId + 1;
};

HomefixLogic.prototype.getMainLocation = function () {
    return this.recentAvailableLocation(this.currentLocationId);
};

HomefixLogic.prototype.getLocationClass = function () {
    return HomefixLocation;
};

HomefixLogic.prototype.listActiveLocations = function () {
    const locationId = this.recentAvailableLocation(this.currentLocationId);

    return [locationId - 2, locationId - 1, locationId].filter(this.isLocationAvailable.bind(this));
};

HomefixLogic.prototype.isCompleted = function () {
    return !this.isLocationAvailable(this.currentLocationId);
};

HomefixLogic.prototype.startLocation = function (locationId) {
    this.currentLocationId = locationId;
};

HomefixLogic.prototype.getCurrentLocationId = function () {
    return this.currentLocationId;
};

HomefixLogic.prototype.processChatMessage = function () {

};

HomefixLogic.prototype.getLocationCompleteReward = function () {
    return {
        hard: 200
    };
};

HomefixLogic.prototype.changeStars = function (stars, silent) {
    this.stars += stars;
    cleverapps.meta.save();

    if (!silent) {
        this.onChangeStars(stars);
    }
};

HomefixLogic.prototype.onChangeStars = function (stars) {
    cleverapps.meta.trigger("changeStars", stars);
};
