/**
 * Created by mac on 4/7/20
 */

const GameMessageView = cc.Node.extend({
    ctor: function (message, options) {
        this.options = options || {};

        this._super();

        this.styles = cleverapps.styles.GameMessageView;

        const background = this.createBackground();
        background.setVisible(false);
        this.addChild(background);
        this.updateSize();

        this.content = this.options.content || this.createContent(message);
        this.content.setLocalZOrder(2);
        background.addChild(this.content);
        this.content.setupChildren = function () {
            this.content.setPositionRound(this.styles.content);
        }.bind(this);
        this.content.setupChildren();

        this.setLocalZOrder(17);
        this.setupChildren();
        this.show();
    },

    setupChildren: function () {
        const scene = cleverapps.scenes.getRunningScene();
        this.background.setPositionRound(scene.width / 2, this.background.y);

        this.start = cc.p(scene.width / 2, scene.height + this.background.height / 2);
        this.target = cc.p(scene.width / 2, scene.height / 2);
        this.finish = cc.p(scene.width / 2, -this.background.height / 2);

        if (this.pattern) {
            this.createPatternLayer(this.pattern, this.background);
        }
    },

    updateSize: function () {
        const sceneSize = cleverapps.resolution.getSceneSize();
        if (this.foreground) {
            this.foreground.setContentSize2(sceneSize.width * 1.2, this.foreground.height);
        }
        this.background.setContentSize2(sceneSize.width * 1.2, this.background.height);
    },

    show: function () {
        cleverapps.audio.playSound(bundles.gamemessage.urls.game_message_effect);
        this.background.setVisible(true);

        if (cleverapps.styles.GameMessageView.showAnimation === "fromTop") {
            this.showFromTop();
        } else {
            this.showFromCenter();
        }
    },

    showFromCenter: function () {
        this.background.setPositionRound(this.target);
        this.background.setScale(1, 0.6);

        this.background.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1, 1).easing(cc.easeBackOut()),
            new cc.DelayTime(0.5),
            new cc.Spawn(
                new cc.FadeOut(0.3),
                new cc.ScaleTo(0.3, 1, 0.6).easing(cc.easeBackIn())
            ),
            new cc.CallFunc(() => {
                this.removeFromParent();
            })
        ));
    },

    showFromTop: function () {
        this.background.setPositionRound(this.start);

        this.background.runAction(new cc.Sequence(
            new cc.MoveTo(0.3, this.target).easing(cc.easeBackOut()),
            new cc.DelayTime(this.duration || 0.8),
            new cc.MoveTo(0.3, this.finish).easing(cc.easeBackIn()),
            new cc.CallFunc(() => {
                this.removeFromParent();
            })
        ));
    },

    createBackground: function () {
        const background = this.background = bundles.gamemessage.frames.gamemessage_bg_png ? new cc.Scale9Sprite(bundles.gamemessage.frames.gamemessage_bg_png) : new cc.Node();
        background.setAnchorPoint(0.5, 0.5);
        const pattern = this.pattern = bundles.gamemessage.frames.gamemessage_pattern_png;
        if (pattern) {
            this.createPatternLayer(pattern, background);
        }
        if (bundles.gamemessage.frames.gamemessage_fg_png) {
            const foreground = this.foreground = new cc.Scale9Sprite(bundles.gamemessage.frames.gamemessage_fg_png);
            foreground.setPositionRound(this.styles.fg);
            foreground.setLocalZOrder(1);
            background.addChild(foreground);
        }
        return background;
    },

    createPatternLayer: function (pattern, parent) {
        if (this.patternLayout) {
            this.patternLayout.removeFromParent();
            delete this.patternLayout;
        }

        const patternWidth = new cc.Sprite(pattern).width;
        const patternAmount = Math.ceil(cleverapps.resolution.getSceneSize().width / patternWidth) + 1;
        const patterns = [];

        for (let i = 0; i <= patternAmount; i++) {
            const patternSprite = new cc.Sprite(pattern);
            patterns.push(patternSprite);
        }

        const patternLayer = this.patternLayout = new cleverapps.Layout(patterns, { direction: cleverapps.UI.HORIZONTAL });
        patternLayer.setLocalZOrder(0);
        patternLayer.setPositionRound(this.styles.pattern);
        parent.addChild(patternLayer);
    },

    createContent: function (message) {
        if (message === "GameMessage.Victory" && typeof VictoryMessageContentView !== "undefined") {
            if (VictoryMessageContentView.getAnimationDuration) {
                this.duration = VictoryMessageContentView.getAnimationDuration();
            }
            return new VictoryMessageContentView(message);
        }

        return GameMessageView.createText(message);
    }
});

GameMessageView.createText = function (message, options) {
    options = options || {};
    message = Messages.has(message) ? Messages.get(message) : message;
    const font = options.font || cleverapps.styles.FONTS.GAME_MESSAGE_TEXT;
    const text = cleverapps.UI.generateTTFText(message.toUpperCase(), font);

    text.setLocalZOrder(1);
    text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
    text.fitTo(cleverapps.resolution.getSceneSize().width * 0.9);
    return text;
};

cleverapps.styles.GameMessageView = {
    showAnimation: "fromTop",
    pattern: {
        x: { align: "center" },
        y: { align: "center" }
    },
    fg: {
        x: { align: "center" },
        y: { align: "center" }
    },
    content: {
        x: { align: "center" },
        y: { align: "center" }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GAME_MESSAGE_TEXT: {
        size: 86,
        color: cleverapps.styles.COLORS.WHITE
    }
});