/**
 * Created by olga on 12.12.2024
 */

var TutorialStep = function (options) {
    this.onStart = options.onStart;
    this.onFinish = options.onFinish;
};

TutorialStep.prototype.isFinished = function () {
    return this.finished;
};

TutorialStep.prototype.start = function () {
    if (this.onStart) {
        this.onStart.call(this);
    }
};

TutorialStep.prototype.finish = function () {
    if (this.finished) {
        if (cleverapps.config.debugMode) {
            throw "Double step execution";
        }
        return;
    }

    this.finished = true;

    this._onFinish();

    if (this.onFinish) {
        this.onFinish.call(this);
    }

    runCleaners(this);
};

TutorialStep.prototype._onFinish = function () {
    if (this.force && cleverapps.forces.getRunningForce() === this.force) {
        cleverapps.forces.closeRunningForce();
    }
};