/**
 * Created by andrey on 15.06.2022.
 */

/* eslint-disable no-extend-native, no-global-assign, no-unused-vars */
 
// ES2022 https://github.com/tc39/proposal-relative-indexing-method
if (Array.prototype.at === undefined) {
    [Array, String].forEach((C) => {
        Object.defineProperty(
            C.prototype, 
            "at",
            {
                writable: true,
                enumerable: false,
                configurable: true,
                value: function at(n) {
                    // ToInteger() abstract op
                    n = (n < 0 ? Math.ceil(n) : Math.floor(n)) || 0;
                    // Allow negative indexing from the end
                    if (n < 0) {
                        n += this.length;
                    }
                    // OOB access is guaranteed to return undefined
                    if (n < 0 || n >= this.length) {
                        return undefined;
                    }
                    return this[n];
                }
            }
        );
    });
}

// ES2021
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (pattern, replacement) {
        if (typeof pattern === "string") { 
            pattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        }

        return this.replace(new RegExp(pattern, "g"), replacement);
    };
}