/**
 * Created by vtbelo on 10.04.18.
 */

var LeadersLoading = cc.Node.extend({
    ctor: function () {
        this._super();

        var waitStyles = cleverapps.styles.WaitWindow;
        var loading = this.loading = new cleverapps.Spine(bundles.main.jsons.wait_json);
        loading.setScale(1.5);
        loading.setAnimation(0, "idle", true);
        this.addChild(loading);

        if (waitStyles.text) {
            var loadingText = this.loadingText = cleverapps.UI.generateTTFText((Messages.get("Loading") || "Loading"), cleverapps.styles.FONTS.WINDOW_TEXT);
            loadingText.setPositionRound(waitStyles.text);
            this.addChild(loadingText);
        }
    },

    remove: function () {
        this.loading.runAction(new cc.RemoveSelf());
        if (this.loadingText) {
            this.loadingText.removeFromParent();
        }
    }
});

var LeadersWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LEADERS_WINDOW_OPEN);

        this.currentLeague = levels.user.league;

        this.leaguesLeaders = {};

        this.content = this.createContent();
        var styles = cleverapps.styles.LeadersWindow;

        this._super.call(this, {
            name: "leaderswindow",
            content: this.content,
            title: "League" + levels.user.league,
            styles: styles.window,
            contentPadding: styles.contentPadding
        });

        this.loadData();
        this.updateArrows();
    },

    createArrows: function () {
        var styles = cleverapps.styles.LeadersWindow.arrows;

        this.decLeagueButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.main.frames.league_arrow_png,
                button_on_png: bundles.main.frames.league_arrow_png
            },
            onClicked: this.decLeague.bind(this)
        });
        this.decLeagueButton.baseScaleX = -1;
        this.decLeagueButton.baseScaley = 1;
        this.decLeagueButton.setScaleX(-1);

        this.incLeagueButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.main.frames.league_arrow_png,
                button_on_png: bundles.main.frames.league_arrow_png
            },
            onClicked: this.incLeague.bind(this)
        });

        return new cleverapps.Layout([this.decLeagueButton, this.incLeagueButton], {
            margin: styles.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.LeadersWindow;
        this.table = this.createTable();
        var arrows = this.createArrows();

        return new cleverapps.Layout([this.table, arrows], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });
    },

    loadData: function () {
        this.loading = new LeadersLoading();
        this.loading.setPositionRound(this.content.width / 2, this.content.height / 2);
        this.content.addChild(this.loading);

        this.loading.setVisible(false);
        this.loading.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.Show()
        ));

        var onLoad = cleverapps.accumulate(300, this.createListener(function (response) {
            this.loading.removeFromParent();
            this.loading = undefined;

            this.leaguesLeaders = {};
            for (var i = 0; i < response.length; i++) {
                this.leaguesLeaders[response[i].leagueId] = JSON.parse(response[i].data);
                this.leaguesLeaders[response[i].leagueId].forEach(function (leader) {
                    leader.league = response[i].leagueId;
                });
            }

            for (i = 0; i < this.getAmountLeagues(); i++) {
                if (!this.leaguesLeaders[i]) {
                    this.leaguesLeaders[i] = [];
                }
            }

            this.updateArrows();
            this.updateTable();
        }.bind(this)));

        var path = "/leaders/" + cleverapps.settings.language + "/";
        if (cleverapps.config.debugMode) {
            path = "https://wordsoup.labsystech.ru/wordsoup-rest" + path;
        }

        cleverapps.RestClient.get(path, {}, onLoad, function () {
            cleverapps.notification.create("LeadersLoadFailed");
        }, {
            ignoreNoRest: cleverapps.config.importMode
        });
    },

    decLeague: function () {
        if (this.currentLeague > 0) {
            this.currentLeague--;
            this.updateArrows();
            this.updateTable();
            this.updateTitle();
        }
    },

    incLeague: function () {
        if (this.currentLeague < this.getAmountLeagues() - 1) {
            this.currentLeague++;
            this.updateArrows();
            this.updateTable();
            this.updateTitle();
        }
    },

    getAmountLeagues: function () {
        var amountLeagues = 1;
        if (cleverapps.config.leagues) {
            amountLeagues = cleverapps.config.leagues.length;
        }
        return amountLeagues;
    },

    updateArrows: function () {
        if (this.decLeagueButton) {
            this.decLeagueButton.setVisible(!this.loading && this.currentLeague > 0);
        }

        if (this.incLeagueButton) {
            this.incLeagueButton.setVisible(!this.loading && this.currentLeague < this.getAmountLeagues() - 1);
        }
    },

    updateTitle: function () {
        if (this.window) {
            this.setTitle("League" + this.currentLeague);
        }
    },

    getTop: function () {
        var top = this.leaguesLeaders[this.currentLeague] || [];
        this.removePlayerFromTop(top);
        if (levels.user.league === this.currentLeague) {
            this.addPlayerToTop(top);
        }

        while (top.length > LeadersWindow.TOP_LIMIT && top[top.length - 1].id !== connector.platform.getUserID()) {
            top.pop();
        }

        return top;
    },

    updateTable: function () {
        var currentLeague = this.currentLeague;

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.01),
            new cc.CallFunc(function () {
                if (currentLeague !== this.currentLeague) {
                    return;
                }

                var position = this.table.getPosition();
                var parent = this.table.getParent();

                this.table.removeFromParent();
                this.table = this.createTable();
                parent.addChild(this.table);
                this.table.setPositionRound(position);
            }.bind(this))
        ));
    },

    removePlayerFromTop: function (top) {
        for (var i = 0; i < top.length; i++) {
            if (top[i].id === connector.platform.getUserID()) {
                top.splice(i, 1);
                break;
            }
        }
    },

    addPlayerToTop: function (top) {
        top.push({
            id: connector.platform.getUserID(),
            rating: levels.user.getVirtualLevel(),
            name: cleverapps.user.getPlayerInfo().name,
            avatar: cleverapps.user.getPlayerInfo().avatar,
            you: true,
            league: levels.user.league
        });

        top.sort(function (a, b) {
            if (a.rating === b.rating) {
                if (a.id === connector.platform.getUserID()) {
                    return -1;
                }
                if (b.id === connector.platform.getUserID()) {
                    return 1;
                }
                return 0;
            }
            return b.rating - a.rating;
        });
    },

    createTable: function () {
        var top = this.getTop();

        var rows = [];
        for (var i = 0; i < top.length; i++) {
            rows.push({
                id: top[i].id,
                score: top[i].rating,
                data: {
                    name: top[i].name,
                    place: (i + 1),
                    avatar: {
                        id: top[i].id,
                        avatar: top[i].avatar
                    },
                    player: top[i].id === connector.platform.getUserID()
                }
            });
        }

        var table = new Table();

        var styles = cleverapps.styles.LeadersWindow.table;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            styles = styles.vertical;
        }

        return new TableView(table, {
            id: "leadersWindow",
            data: rows,
            height: styles.height,
            rowOptions: {
                width: styles.width,
                prizes: false
            }
        });
    },

    listBundles: function () {
        return ["leaders_window"];
    }
});

cleverapps.styles.LeadersWindow = {
    margin: 20,
    table: {
        vertical: {
            width: 728,
            height: 800
        }
    },

    padding: {
        x: -30
    },

    arrows: {
        margin: 110
    },

    window: {
        decors: {
        }
    }
};

LeadersWindow.TOP_LIMIT = 100;
