/**
 * Created by vladislav on 17.09.2020
 */

const ProgressView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.hoverContainer = new cc.Node();
        this.hoverContainer.setAnchorPoint(0.5, 0.5);
        this.addChild(this.hoverContainer);

        this.addProgressBar();
        this.addArrows();
        this.addIcons();
        cleverapps.UI.wrap(this.hoverContainer);
        this.setContentSize2(this.hoverContainer.getContentSize());
        this.hoverContainer.setPositionRound(this.width / 2, this.height / 2);

        cleverapps.UI.onClick(this.hoverContainer, this.onClick.bind(this));

        const position = cleverapps.styles.ProgressView.positions || cleverapps.styles.ProgressView;
        this.setPositionRound(position);

        this.setCascadeOpacityEnabledRecursively(true);

        cleverapps.aims.registerTarget("progress", this, {
            controls: ["progress_view", "play_button"],
            withoutDelta: true,
            noTargetDelta: true,
            flyingUnderShadow: true,
            flyingAnimation: Reward.NO_ANIMATION
        });

        cleverapps.meta.getSelectedLocation().on("animateProgressChange", this.updateProgress.bind(this), this);
        cleverapps.meta.getSelectedLocation().on("showForce", this.showForce.bind(this), this);

        SceneDecors.add(this.hoverContainer, cleverapps.skins.getSlot("progressViewDecor"));
    },

    onClick: function () {
        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        cleverapps.audio.playSound(bundles.main.urls.click_effect);

        if (!cleverapps.config.rpg) {
            cleverapps.focusManager.display({
                focus: "BackgroundsWindow",
                action: function (f) {
                    new BackgroundsWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    },

    showForce: function () {
        cleverapps.focusManager.setEventNodes([this]);
        FingerView.hintTap(this, {
            runOnce: true
        });
    },

    runProgressAnimation: function () {
        const styles = cleverapps.styles.ProgressView;

        const progressBarAnimation = new cleverapps.Spine(bundles.simple.jsons.progress_bar_spark_json);
        this.hoverContainer.addChild(progressBarAnimation);
        progressBarAnimation.setPositionRound(styles.bar.spark);
        progressBarAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.CallFunc(() => {
                progressBarAnimation.setAnimation(0, "animation", false);
                progressBarAnimation.setCompleteListenerRemove();
            })
        ));
    },

    runAvatarAnimation: function () {
        if (cleverapps.config.rpg) {
            return;
        }

        const avatarAnimation = new cleverapps.Spine(bundles.simple.jsons.avatar_spark_json);
        this.hoverContainer.addChild(avatarAnimation);
        avatarAnimation.setPositionRound(this.nextIcon.getPosition());
        avatarAnimation.setCompleteListenerRemove();
        avatarAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(() => {
                avatarAnimation.setAnimation(0, "animation", false);
                cleverapps.audio.playSound(bundles.simple.urls.simple_win_effect);
            })
        ));
    },

    runStarAnimation: function (delay, timeScale, speed, callback) {
        const styles = cleverapps.styles.ProgressView;

        const scene = cleverapps.scenes.getRunningScene();

        const target = this.progressBar.bar.barText.items[0];
        const targetPos = scene.convertToNodeSpace(target.getParent().convertToWorldSpace(target.getPosition()));

        targetPos.x += styles.star.x;
        targetPos.y += styles.star.y;

        const star = new cleverapps.Spine(bundles.simple.jsons.star_json);
        scene.addChild(star);
        star.setPositionRound(targetPos.x, scene.height / 2);
        star.setLocalZOrder(10);
        star.setTimeScale(timeScale);

        star.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.CallFunc(() => {
                star.setAnimation(0, "collect", false);
                star.setCompleteListenerRemove();
            }),
            new cc.MoveTo(speed, targetPos).easing(cc.easeIn(2)),
            new cc.PlaySound(bundles.simple.urls.star_collect_effect),
            new cc.CallFunc(() => {
                this.progressBar.updateProgress(1);

                callback();
            })
        ));
    },

    updateProgress: function (addedStars, callback) {
        const styles = cleverapps.styles.ProgressView;

        const controls = ["progress_view"];
        if (!cleverapps.meta.getSelectedLocation().isCompleted()) {
            controls.push("play_button");
        }
        cleverapps.focusManager.showControlsWhileFocused(controls);

        const onStarsFinished = cleverapps.wait(addedStars, () => {
            if (!cleverapps.meta.isCompleted() && cleverapps.meta.getSelectedLocation().isCompleted()) {
                this.runProgressAnimation();

                this.runAvatarAnimation();
            }

            let actions = new cc.CallFunc(() => {
                cleverapps.aims.showDelta(addedStars, this.progressBar, {
                    x: styles.delta.x,
                    y: styles.delta.y,
                    font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT
                });
            });

            if (callback) {
                actions = new cc.Spawn(
                    actions,
                    new cc.Sequence(
                        new cc.DelayTime(1.2),
                        new cc.CallFunc(callback)
                    )
                );
            }

            this.runAction(actions);
        });

        const maxTime = 5;
        const baseDelay = 0.4;

        let delay = baseDelay;
        if (baseDelay * addedStars > maxTime) {
            delay = maxTime / addedStars;
        }

        const timeScale = baseDelay / delay;
        const speed = delay / baseDelay;

        for (let i = 0; i < addedStars; i++) {
            this.runStarAnimation(i * delay, timeScale, speed, onStarsFinished);
        }
    },

    addProgressBar: function () {
        const styles = cleverapps.styles.ProgressView;

        const location = cleverapps.meta.getSelectedLocation();

        this.progressBar = new StarsProgressBar({
            width: styles.bar.width,
            stars: location.getPendingProgress() > 0 ? location.getProgress() - location.getPendingProgress() : undefined
        });
        this.hoverContainer.addChild(this.progressBar);
    },

    addArrows: function () {
        const styles = cleverapps.styles.ProgressView.arrows;

        if (!styles) {
            return;
        }

        const arrowWidth = new cc.Sprite(bundles.simple.frames.arrow_png).width;
        const amount = (this.progressBar.width - styles.widthOffset) / (arrowWidth + styles.margin);

        const arrows = [];
        for (let i = 0; i < amount; i++) {
            arrows.push(new cc.Sprite(bundles.simple.frames.arrow_png));
        }

        const layout = new cleverapps.Layout(arrows, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        layout.setLocalZOrder(-1);

        this.progressBar.bar.addChild(layout);
        layout.setPositionRound(styles);
    },

    addIcons: function () {
        if (cleverapps.config.rpg) {
            return;
        }

        const styles = cleverapps.styles.ProgressView;

        const currentIcon = this.currentIcon = this.createIcon();
        this.hoverContainer.addChild(currentIcon);
        currentIcon.setPositionRound(-styles.bar.width / 2 - styles.icon.offset.x, this.height / 2 + styles.icon.offset.y);
        currentIcon.setRotation(-styles.icon.rotation || 0);

        const nextIcon = this.nextIcon = this.createIcon(true);
        nextIcon.setRotation(styles.icon.rotation || 0);
        this.hoverContainer.addChild(nextIcon);
        nextIcon.setPositionRound(styles.bar.width / 2 + styles.icon.offset.x, this.height / 2 + styles.icon.offset.y);
        if (!cleverapps.meta.nextLocationId(cleverapps.meta.selectedLocationId())) {
            nextIcon.addChild(this.createLock());
        }
    },

    createIcon: function (isNext) {
        let icon;
        if (isNext) {
            icon = new cc.Sprite(bundles[cleverapps.meta.getSelectedLocation().getIconBundle(cleverapps.meta.selectedLocationId() + 1)].urls.icon);
        } else {
            icon = new cc.Sprite(bundles[cleverapps.meta.getSelectedLocation().getIconBundle(cleverapps.meta.selectedLocationId())].urls.icon);
        }

        const iconBg = this.createIconBg(icon, isNext);

        const node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(iconBg.getContentSize());
        node.addChild(icon);
        node.addChild(iconBg);
        node.setLocalZOrder(10);

        icon.setPositionRound(node.width / 2, node.height / 2);

        return node;
    },

    createLock: function () {
        const styles = cleverapps.styles.ProgressView;

        const lock = new cc.Sprite(bundles.simple.frames.lock_png);
        lock.setLocalZOrder(10);
        lock.setScale(styles.comingSoon.lock.scale);
        lock.setPositionRound(styles.comingSoon.lock);

        return lock;
    },

    createIconBg: function (icon, isNext) {
        let styles = cleverapps.styles.ProgressView.icon.bg;

        const iconBg = new cc.Scale9Sprite(isNext ? bundles.simple.frames.frame_next_png || bundles.simple.frames.frame_silver_png : bundles.simple.frames.frame_current_png || bundles.simple.frames.frame_gold_png);

        if (styles.flipped) {
            styles = styles.flipped;

            if (isNext) {
                iconBg.setScaleX(-1);
            }
            iconBg.setPositionRound(isNext ? styles.right : styles.left);
        } else {
            iconBg.setContentSize2(icon.width * icon.scaleX + 2 * styles.padding.x, icon.height * icon.scaleY + 2 * styles.padding.y);
            iconBg.setPositionRound(styles);
        }

        return iconBg;
    }
});

cleverapps.styles.ProgressView = {
    x: { align: "center" },
    y: { align: "bottom", dy: 250 },

    bar: {
        width: 350,

        spark: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    star: {
        x: 0,
        y: -12
    },

    arrows: {
        x: { align: "center" },
        y: { align: "center", dy: 4 },
        widthOffset: 40,
        margin: 15
    },

    icon: {
        offset: {
            y: 6,
            x: 52
        },
        bg: {
            padding: {
                x: 11,
                y: 12
            },
            x: { align: "center" },
            y: { align: "center", dy: -2 }
        }
    },

    comingSoon: {
        lock: {
            x: { align: "center" },
            y: { align: "center" },
            scale: 0.3
        },
        icon: {
            width: 96,
            height: 92
        }
    },

    delta: {
        x: { align: "center" },
        y: { align: "top", dy: 50 }
    }
};
