/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["simpleUpdateProgress"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -2,

    filter: function () {
        return cleverapps.meta.getSelectedLocation().getPendingProgress() > 0;
    },

    action: function () {
        cleverapps.meta.getSelectedLocation().animateProgressChange();
    }
};

Placements.ENTRIES["simpleMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        const location = cleverapps.meta.getSelectedLocation();
        const nextLocationId = cleverapps.meta.nextLocationId(location.locationId);

        return location.isCompleted()
            && !cleverapps.meta.listActiveLocations().includes(nextLocationId)
            && !cleverapps.meta.isCompleted();
    },

    action: function () {
        cleverapps.meta.getSelectedLocation().moveNextLocation();
    }
};