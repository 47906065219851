/**
 * Created by razial on 13.12.2024.
 */

const DefaultMetaAdapter = function () {

};

DefaultMetaAdapter.prototype.load = function () {
    this.updateInfo(cleverapps.dataLoader.load(DataLoader.TYPES.META));
};

DefaultMetaAdapter.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

DefaultMetaAdapter.prototype.updateInfo = function () {

};

DefaultMetaAdapter.prototype.getInfo = function () {
    return {};
};

DefaultMetaAdapter.prototype.loadLocation = function () {
    return {};
};

DefaultMetaAdapter.prototype.saveLocation = function () {

};

DefaultMetaAdapter.prototype.reset = function () {

};