/**
 * Created by mac on 9/16/23
 */

var Tutorial = function () {
    cleverapps.EventEmitter.call(this);
    this.active = undefined;
    this.scenarios = [];
    this.onFinishCallback = function () { };
};

Tutorial.prototype = Object.create(cleverapps.EventEmitter.prototype);
Tutorial.prototype.constructor = Tutorial;

Tutorial.prototype.register = function (scenarioCreator) {
    this.scenarios.push(scenarioCreator);
    this.scenarios.sort(function (a, b) {
        return b.priority - a.priority;
    });
};

Tutorial.prototype.isActive = function () {
    return this.active !== undefined;
};

Tutorial.prototype.getActive = function () {
    return this.active;
};

Tutorial.prototype.getCurrentStep = function () {
    return this.active && this.active.getCurrentStep();
};

Tutorial.prototype.getInfo = function () {
    var info = this.active ? {
        name: this.active.name,
        stepNo: this.active.stepNo
    } : undefined;
    return info;
};

Tutorial.prototype.run = function (Scenario, f) {
    this.active = Scenario;

    this.onFinishCallback = f;
    Scenario.start();
};

Tutorial.prototype.startNextStep = function () {
    if (!this.active) {
        return;
    }

    var step = this.active.getCurrentStep();
    if (!step.isFinished()) {
        return;
    }
  
    this.active.startNextStep();

    if (!this.active.getCurrentStep()) {
        this.active.onFinishScenario();
        this.active = undefined;
        this.onFinishCallback();
    }
};

Tutorial.prototype.restoreSavedTutorial = function () {
    if (!cleverapps.loadedSnapshot || !cleverapps.loadedSnapshot.tutorial) {
        return;
    }

    var savedData = cleverapps.loadedSnapshot.tutorial;
    delete cleverapps.loadedSnapshot.tutorial;

    var savedScenario = this.scenarios.find(function (scenario) {
        return scenario.name === savedData.name;
    });
    if (!savedScenario) {
        console.log(savedData, this.scenarios);
        throw "no such tutorial " + savedData.name;
    }

    savedScenario.setStep(savedData.stepNo);

    return savedScenario;
};

Tutorial.prototype.getAvailableScenario = function () {
    return this.scenarios.find(function (scenario) {
        return scenario.isAvailable();
    });
};

Tutorial.prototype.startScenario = function (scenario) {
    scenario = scenario || this.getAvailableScenario();

    var saveScenario = this.restoreSavedTutorial();
    if (saveScenario) {
        scenario = saveScenario;
    } else if (scenario) {
        scenario.setStep(0);
    }

    if (scenario) {
        cleverapps.focusManager.display({
            focus: scenario.name,
            control: [],
            stack: true,
            actions: [
                function (f) {
                    this.run(scenario, f);
                }.bind(this)
            ]
        });
    }
};

Tutorial.prototype.afterResize = function () {
    this.trigger("onResize");
};

Tutorial.prototype.resetActive = function () {
    this.active = undefined;
};