/**
 * Created by Anastasiya on 01.03.2024
 */

// animations descriptions: https://docs.google.com/spreadsheets/d/1g24GcHLbiRM-3ALfqUD6R-hCTnh81b2gyKMal_FWNXo/edit?gid=1560161212#gid=1560161212

const StandartAnimations = {};

StandartAnimations.run = function (animation, node, options) {
    node.runAction(StandartAnimations[animation].call(node, node, options));
};

StandartAnimations.squeeze = function (node) {
    const duration = 0.2;
    const halfDuration = duration / 2;

    const dScale = node.height < cleverapps.styles.StandartAnimations.squeeze.smallHeight ? 0.1 : 0.05;

    const height = dScale / 2 * node.height;

    return new cc.Sequence(
        new cc.Spawn(
            new cc.ScaleTo(halfDuration, 1 + dScale, 1 - dScale),
            new cc.MoveBy(halfDuration, 0, -height)
        ),
        new cc.Spawn(
            new cc.ScaleTo(halfDuration, 1, 1),
            new cc.MoveBy(halfDuration, 0, height)
        )
    );
};

StandartAnimations.fallDown = function (node, dustAnimation) {
    dustAnimation = dustAnimation || bundles.animations_library.jsons.fall_down_json;

    const mapView = Map2d.currentMap.getMapView();

    let pos = mapView.alignInGrid(node.unit.x, node.unit.y);
    if (node.unit.isMultiCell()) {
        pos = mapView.alignPositionInGrid(
            node.unit.x,
            node.unit.y,
            MultiCellView.calcCenterPosition(node.unit.findComponent(MultiCell).shape, node)
        );
    }

    return new cc.Spawn(
        new cc.MoveBy(0.25, pos.x - node.x, 0).easing(cc.easeOut(2)),
        new cc.MoveBy(0.15, 0, pos.y - node.y).easing(cc.easeIn(1)),
        new cc.CallFunc(() => {
            const dustSpine = new cleverapps.Spine(dustAnimation);
            dustSpine.setPositionRound({
                x: { align: "center" },
                y: { align: "center" }
            });
            dustSpine.setCompleteListenerRemove();
            dustSpine.setSafeToRemove();
            dustSpine.setLocalZOrder(-1);
            mapView.addTile(Map2d.LAYER_UNITS, node.unit.x, node.unit.y, dustSpine);
            dustSpine.setAnimation(0, node.unit.isMultiCell() ? "animation_multicell" : "animation", false);

            cleverapps.audio.playSound(node.unit.isMultiCell() ? bundles.merge.urls.unit_fall_multicell_effect : bundles.merge.urls.unit_fall_effect);
        })
    );
};

StandartAnimations.jump = function () {
    return new cc.Spawn(
        new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.MoveBy(0.2, 0, 20),
            new cc.MoveBy(0.2, 0, -20)
        ),
        new cc.Sequence(
            new cc.ScaleTo(0.4, 1.1, 0.90).easing(cc.easeCubicActionOut()),
            new cc.ScaleTo(0.2, 0.85, 1.15),
            new cc.ScaleTo(0.3, 1.05, 0.95),
            new cc.ScaleTo(0.1, 1, 1)
        )
    );
};

StandartAnimations.squeezeJump = function (source, options) {
    options = options || {};
    const jumpHeight = options.jumpHeight || cleverapps.styles.StandartAnimations.squeezeJump.jumpHeight;
    const baseScale = { x: 1, y: 1 };
    if (options.baseScale) {
        baseScale.x = options.baseScale.x || options.baseScale;
        baseScale.y = options.baseScale.y || options.baseScale;
    }
    let anchorPoint;
    return new cc.Sequence(
        new cc.CallFunc(() => {
            anchorPoint = source.getAnchorPoint();
            source.setAnchorPoint(anchorPoint.x, 0);
            source.setPosition(source.getPosition().x, source.getPosition().y - source.height * anchorPoint.y);
        }),
        new cc.ScaleTo(0.1, 1.25 * baseScale.x, 0.8 * baseScale.y),
        new cc.Spawn(
            new cc.Sequence(
                new cc.ScaleTo(0.1, 0.5 * baseScale.x, 1.3 * baseScale.y),
                new cc.ScaleTo(0.15, baseScale.x, baseScale.y).easing(cc.easeOut(2))
            ),
            new cc.Sequence(
                new cc.DelayTime(0.08),
                new cc.MoveBy(0.5, 0, jumpHeight).easing(cc.easeBackOut())
            )
        ),
        new cc.CallFunc(() => {
            source.setAnchorPoint(anchorPoint);
            source.setPosition(source.getPosition().x, source.getPosition().y + source.height * anchorPoint.y);
        })
    );
};

StandartAnimations.complete = function (node, options) {
    options = options || {};

    const stripe = options.stripe || bundles.animations_library.jsons.complete_json;

    const stripeAction = new cc.CallFunc(() => {
        const completeSpine = new cleverapps.Spine(stripe);
        completeSpine.setCompleteListenerRemove();
        completeSpine.setSafeToRemove();
        completeSpine.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        node.addChild(completeSpine);
        completeSpine.setScale(node.height / completeSpine.height);
        completeSpine.setAnimation(0, "animation", false);
    });

    return options.pulse === false ? stripeAction : new cc.Sequence(
        stripeAction,
        StandartAnimations.pulse(node, { duration: 0.6, steps: 2, scaleRate: 1.07 })
    );
};

StandartAnimations.rays = function (node, options) {
    options = options || {};
    const skin = options.skin || "yellow";
    const size = options.size || node.height;
    const raysSpine = new cleverapps.Spine(bundles.animations_library.jsons.rays_json);
    raysSpine.setSkin(skin);
    raysSpine.setScale(size / raysSpine.height);
    node.addChild(raysSpine, -1);
    raysSpine.setPositionRound({
        x: { align: "center" },
        y: { align: "center" }
    });
    raysSpine.setAnimationAndIdleAfter("open", "idle");

    return raysSpine;
};

StandartAnimations.upgrade = function (nodeOld, nodeNew, options) {
    options = options || {};

    const actions = [];

    if (options.cloud) {
        const animation = bundles.animations_library.jsons.change_stage_cloud_json;
        const animationName = "animation";
        const cloud = new cleverapps.Spine(animation);

        nodeOld.addChild(cloud);
        cloud.setPosition(nodeOld.width / 2, nodeOld.height / 2);
        cloud.replaceParentSamePlace(cleverapps.scenes.getMovingNode(nodeOld), {
            keepScale: true
        });
        cleverapps.UI.fitToBox(cloud, {
            maxScale: 1.6,
            minScale: 1,
            width: Math.max(nodeOld.width, nodeNew && nodeNew.width || 0) * 1.05,
            height: Math.max(nodeOld.height, nodeNew && nodeNew.height || 0) * 1.05
        });
        cloud.setAnimation(0, animationName, false);
        cloud.setCompleteListenerRemove();

        actions.push(new cc.DelayTime(cloud.getAnimationData(animationName).duration));
    }

    actions.push(new cc.Sequence(
        StandartAnimations.disappear(nodeOld, {
            dust: nodeOld !== nodeNew || !options.cloud
        })
    ));

    if (nodeNew) {
        const showupNode = nodeNew.sprite || nodeNew; // nodeNew can be UnitView
        showupNode.stopAllActions();

        const baseScaleX = showupNode.getScaleX();
        const baseScaleY = showupNode.getScaleY();
        const basePosition = showupNode.getPosition();
        const nodeNewPosition = nodeNew.getPosition();

        const upgradeSameNode = nodeOld === nodeNew;

        actions.push(new cc.Sequence(
            new cc.DelayTime(0.34 * upgradeSameNode),
            new cc.CallFunc(() => {
                showupNode.setVisible(false);
                if (nodeNew.makePartsVisible) {
                    nodeNew.makePartsVisible(false);
                }
                if (upgradeSameNode) {
                    nodeNew.setPositionRound(nodeNewPosition);
                }
            }),
            new cc.DelayTime(0.34 * (nodeOld !== nodeNew)),
            new cc.CallFunc(() => {
                showupNode.runAction(StandartAnimations.showUp(showupNode, { flash: options.flash }))
                    .setFinalize(() => {
                        if (nodeNew.makePartsVisible) {
                            nodeNew.makePartsVisible(true);
                        }
                        showupNode.setScale(baseScaleX, baseScaleY);
                        showupNode.setPositionRound(basePosition);
                    });
            })
        ));
    }

    return new cc.Sequence(
        new cc.Spawn(actions),
        new cc.CallFunc(() => {
            if (nodeNew !== nodeOld) {
                nodeOld.removeFromParent();
            }
        })
    );
};

StandartAnimations.ballEffect = function (node) {
    const dScale = node.height < cleverapps.styles.StandartAnimations.squeeze.smallHeight ? 0.1 : 0.05;
    const height = node.height * dScale;

    return new cc.Sequence(
        new cc.Spawn(
            new cc.ScaleTo(0.1, 1 + dScale / 2, 1 - dScale / 2),
            new cc.MoveBy(0.1, 0, height).easing(cc.easeOut(1))
        ),
        new cc.Spawn(
            new cc.ScaleTo(0.1, 1),
            new cc.MoveBy(0.1, 0, -height).easing(cc.easeIn(3))
        )
    );
};

StandartAnimations.shake = function (node, options) {
    options = options || {};
    const power = options.power || 1;
    const moveTime = options.moveTime || 0;
    const delayTime = options.delayTimeAfterMove !== undefined ? options.delayTimeAfterMove : 0.05;

    let strength = options.strength;
    if (!strength) {
        strength = cleverapps.styles.StandartAnimations.shake;
    }

    const points = options.points || [
        { x: 2, y: 1, r: 0 },
        { x: -1, y: -2, r: -1 },
        { x: -3, y: 0, r: 1 },
        { x: 0, y: 2, r: 0 },
        { x: 1, y: -1, r: 1 },
        { x: 0, y: 0, r: 0 }
    ];

    let base = options.base;

    if (!base) {
        base = {
            x: node.x,
            y: node.y,
            r: node.rotation
        };
    }

    const shiftActions = points.map((point) => new cc.Spawn(
        new cc.MoveTo(moveTime, base.x + point.x * strength.dx * power, base.y + point.y * strength.dy * power),
        new cc.RotateTo(moveTime, base.r + point.r)
    ));

    const actions = [];

    shiftActions.forEach((action) => {
        actions.push(action);
        actions.push(new cc.DelayTime(delayTime));
    });

    return new cc.Sequence(actions);
};

StandartAnimations.pulse = function (node, options) {
    const SCALE_RATE = options.scaleRate || 1.3;
    const FPS = 1;
    const duration = options.duration || 0.5;
    const steps = options.steps || Math.ceil(FPS * duration);
    const stepDuration = duration / steps;
    const actions = [];
    const baseScale = node.baseScale || node.getScale();
    for (let i = 0; i < steps; i++) {
        actions.push(new cc.Sequence(
            new cc.ScaleTo(stepDuration / 2, baseScale * SCALE_RATE),
            new cc.ScaleTo(stepDuration / 2, baseScale)
        ));
    }
    return new cc.Sequence(actions);
};

StandartAnimations.countTo = function (node, newValue, options) {
    const duration = options.duration || 1;
    const message = options.message;

    const currentText = node.getString();

    const parts = currentText.split("/");

    let slash = "/";
    if (parts[0][parts[0].length - 1] === " ") {
        slash = " / ";
    }

    let currentValue = options.from || +parts[0].trim();
    const total = parts[1] && +parts[1].trim();

    const FPS = 30;
    const delta = newValue - currentValue;
    const steps = Math.min(Math.ceil(FPS * duration), Math.abs(delta));
    const increment = delta / steps;

    const updateValueAction = function (value) {
        return new cc.Sequence(
            new cc.CallFunc(() => {
                if (message) {
                    node.setString(Messages.get(message, { value, total, slash }));
                } else {
                    node.setString(total !== undefined ? value + slash + total : value);
                }
            }),
            new cc.DelayTime(duration / steps)
        );
    };

    const actions = [];
    for (let i = 0; i < steps; i++) {
        currentValue += increment;
        actions.push(updateValueAction(Math.round(currentValue)));
    }

    return new cc.Sequence(actions);
};

StandartAnimations.jumpCollect = function (source, target, options) {
    options = options || {};
    target = cleverapps.aims.getTarget(target);

    let styles = cleverapps.styles.StandartAnimations.jumpCollect;
    const jumpMovingNode = cleverapps.scenes.getMovingNode(source);
    const collectMovingNode = options.collectMovingNode || cleverapps.scenes.getMovingNode(target);
    const duration = options.duration || cc.CollectAnimation.calcDuration({ targetNode: source, to: target, jump: true });
    const jumpScale = options.jumpScale || source.scale * 1.2;
    const collectScale = options.collectScale || source.scale;

    source.replaceParentSamePlace(jumpMovingNode, {
        keepScale: true
    });

    styles = options.longJump ? styles.longJump : styles.jump;
    let jumpStyles = styles.both;

    if (options.jumpSide) {
        if (options.jumpSide === "left") {
            jumpStyles = styles.left;
        } else {
            jumpStyles = styles.right;
        }
    }
    if (options.longJump) {
        jumpStyles = styles.right;
    }

    const jumpOffset = options.jumpOffset || 0;
    const jumpPosition = cc.p(
        jumpStyles.x1 + (jumpStyles.x2 - jumpStyles.x1) * jumpOffset,
        jumpStyles.y1 + Math.random() * (jumpStyles.y2 - jumpStyles.y1)
    );

    const actions = [
        new cc.CallFunc(() => {
            cleverapps.aims.showTarget(target);
        })
    ];

    actions.push(new cc.Spawn(
        options.longJump
            ? new cc.JumpTo(0.8, source.x + jumpPosition.x, source.y + jumpPosition.y, styles.height * (0.9 + 0.2 * Math.random()), 2).easing(cc.easeInOut(1.2))
            : new cc.JumpAnimation(0.4, source, jumpPosition, styles.height),
        new cc.ScaleTo(0.4, jumpScale),
        new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.PlaySound(bundles.main.urls.coins_drop_effect)
        )
    ));

    if (options.longJump) {
        actions.push(new cc.CallFunc(() => {
            cleverapps.aims.showTarget(target);
        }));
        actions.push(new cc.DelayTime((options.collectDelay || 0) + 0.5 * (1 - jumpOffset)));
    }

    actions.push(new cc.ReplaceParent(collectMovingNode));

    actions.push(new cc.Spawn(
        new cc.CollectAnimation(duration, target, {
            jump: true
        }),
        new cc.ScaleTo(duration, collectScale)
    ));

    actions.push(new cc.PlaySound(options.collectSound, { throttle: 0 }));

    if (options.collectEffect) {
        actions.push(new cc.CollectEffect(target));
    }

    return new cc.Sequence(actions);
};

StandartAnimations.coinCloud = function (node, options) {
    options = options || {};
    options.cloudSize = options.cloudSize || 0;
    const target = options.target || cleverapps.aims.getTarget(["rewardHard", "hard"]);
    const styles = cleverapps.styles.StandartAnimations.coinCloud;
    const coinStyle = styles.coins[options.cloudSize];
    const coins = [];
    const actions = [];

    actions.push(
        new cc.CallFunc(() => {
            const sparkAnimation = new cleverapps.Spine(bundles.animations_library.jsons.coins_reward_json);
            sparkAnimation.setPositionRound(styles.sparks.position);
            node.addChild(sparkAnimation);
            sparkAnimation.setAnimation(0, "animation", false);
            sparkAnimation.setCompleteListenerRemove();
        })
    );

    options.reward && actions.push(
        new cc.CallFunc(() => {
            const text = cleverapps.UI.generateTTFText(`+${options.reward}`, cleverapps.styles.FONTS.COINS_REWARD_TEXT);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            text.setScale(styles.text.scale / 2);
            text.setLocalZOrder(1);

            text.setPositionRound(styles.text.position);
            node.addChild(text);
            text.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
            text.runAction(new cc.Sequence(
                new cc.FadeIn(0.2),
                new cc.ScaleTo(styles.showTextTime[options.cloudSize], styles.text.scale),
                new cc.FadeOut(0.2),
                new cc.RemoveSelf()
            ));
        })
    );
    const APPEAR_TIME = 0.4;
    actions.push(new cc.CallFunc(() => {
        for (let i = 0; i < coinStyle.length; i++) {
            for (let j = 0; j < coinStyle[i].count; j++) {
                const coin = new cc.Sprite(bundles.animations_library.frames.animations_coin);

                coin.setRotation((Math.random() - 0.5) * 120);
                coin.setScale(0);
                node.addChild(coin);

                const angle = 2 * Math.PI * j / coinStyle[i].count;

                const baseAngle = (coinStyle[i].baseAngle || 0) * Math.PI / 180;
                let posX = coinStyle[i].radiusX * Math.cos(angle + baseAngle);
                let posY = coinStyle[i].radiusY * Math.sin(angle + baseAngle);

                posX += (Math.random() - 0.5) * 0.5 * posX;
                posY += (Math.random() - 0.5) * 0.5 * posY;

                coin.setPosition(node.width / 2 + posX, node.height / 2 + posY);
                coin.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
                coin.runAction(new cc.Spawn(
                    new cc.MoveBy(APPEAR_TIME, cc.p(
                        posX * 0.2,
                        posY * 0.2
                    )),
                    new cc.ScaleTo(APPEAR_TIME, coinStyle[i].scale)
                ));
                coins.push(coin);
            }
        }
        const COLLECT_COIN_TIME = 0.5;
        coins.sort((a, b) => a.getPositionX() - b.getPositionX()).forEach((coin, index) => {
            coin.runAction(new cc.Sequence(
                new cc.DelayTime(APPEAR_TIME + index * 0.01),
                new cc.CallFunc(() => {
                    cleverapps.aims.showTarget(target);
                }),
                new cc.Spawn(
                    new cc.CollectAnimation(COLLECT_COIN_TIME, target, {
                        jump: true
                    }),
                    new cc.RotateBy(COLLECT_COIN_TIME, 180)
                ),
                new cc.CallFunc(() => {
                    if (index === 0) {
                        cleverapps.aims.animateTarget(target, options.reward);
                    }
                }),
                new cc.RemoveSelf()
            ));
        });
    }));
    return new cc.Sequence(actions);
};

StandartAnimations.shift = function () {
    const styles = cleverapps.styles.StandartAnimations.shift;
    return new cc.Sequence(
        new cc.MoveBy(0.2, 0, styles.shift).easing(cc.easeOut(1)),
        new cc.MoveBy(0.2, 0, -styles.shift).easing(cc.easeIn(1))
    );
};

StandartAnimations.hovering = function (node, options) {
    options = options || {};
    const styles = cleverapps.styles.StandartAnimations.hovering;

    const animationTime = options.animationTime || 7;
    const leftAngle = options.leftAngle || 6;
    const rightAngle = options.rightAngle || 3;
    const offsetY = styles.offsetY;

    const particlesSpine = node.particles = new cleverapps.Spine(bundles.animations_library.jsons.hovering_json);
    particlesSpine.setScale(node.height / particlesSpine.height);
    node.addChild(particlesSpine, -1);
    particlesSpine.setPositionRound(styles.particles);

    return new cc.Sequence(
        new cc.CallFunc(() => {
            particlesSpine.setAnimation(0, "animation", true);
        }),
        new cc.CallFunc(() => {
            node.runAction(new cc.RepeatForever(
                new cc.Spawn(
                    new cc.Sequence(
                        new cc.RotateBy(3 * animationTime / 10, leftAngle).easing(cc.easeInOut(2)),
                        new cc.RotateBy(4 * animationTime / 10, -(leftAngle + rightAngle)).easing(cc.easeInOut(2)),
                        new cc.RotateBy(3 * animationTime / 10, rightAngle)
                    ),
                    new cc.Sequence(
                        new cc.MoveBy(animationTime / 2, 0, -offsetY).easing(cc.easeInOut(2)),
                        new cc.MoveBy(animationTime / 2, 0, offsetY).easing(cc.easeInOut(2))
                    )
                )
            ));
        })
    );
};

StandartAnimations.animateCollect = function (source, target, options) {
    options = options || {};
    target = cleverapps.aims.getTarget(target);

    const movingNode = cleverapps.scenes.getMovingNode(target);

    const duration = options.duration || cc.CollectAnimation.calcDuration({ targetNode: source, to: target });
    const baseScaleX = source.baseScaleX || source.baseScale || 1;
    const baseScaleY = source.baseScaleY || source.baseScale || 1;
    const targetScale = options.adjustToTarget ? Math.min(target.width / source.width, target.height / source.height, 1) : options.scale;

    const actions = [new cc.ReplaceParent(movingNode)];

    let path;
    if (options.path) {
        path = new cleverapps.Particles(bundles.particle.jsons.particles_collect_json, bundles.particle.frames.particle_texture);
        actions.push(new cc.CallFunc(() => {
            movingNode.addChild(path, -1);
            path.setPositionRound(movingNode.convertToNodeSpace(source.convertToWorldSpace(cc.p(source.width / 2, source.height / 2))));
            path.followTarget(source);
        }));
    }

    actions.push(new cc.CallFunc(() => {
        cleverapps.aims.showTarget(target);
    }));

    let collectAnimation = new cc.CollectAnimation(duration, target, options);

    if (options.easing) {
        collectAnimation = collectAnimation.easing(options.easing);
    }

    actions.push(new cc.Spawn(
        collectAnimation,
        new cc.Sequence(
            new cc.ScaleTo(duration * 0.4, baseScaleX * 1.3, baseScaleY * 1.3),
            new cc.ScaleTo(duration * 0.6, targetScale || baseScaleX, targetScale || baseScaleY)
        )
    ));

    if (options.sound) {
        actions.push(new cc.PlaySound(options.sound, { throttle: 0 }));
    }
    if (options.collectEffect) {
        actions.push(new cc.CollectEffect(target));
    }

    if (path) {
        actions.push(new cc.CallFunc(() => {
            path.stop();
        }));
    }

    return new cc.Sequence(actions);
};

StandartAnimations.showUp = function (node, options) {
    options = options || {};
    const move = options.move || cleverapps.styles.StandartAnimations.showUp.fromBottom;

    const baseScaleX = node.getScaleX();
    const baseScaleY = node.getScaleY();
    const basePosition = node.getPosition();

    const actions = [new cc.MoveBy(0, move)];

    if (options.flash) {
        actions.push(
            new cc.CallFunc(() => {
                const showUpSpine = new cleverapps.Spine(bundles.animations_library.jsons.show_up_json);
                node.addChild(showUpSpine);
                showUpSpine.setPositionRound({ align: "center" }, { align: "center" });
                showUpSpine.replaceParentSamePlace(node.parent);
                showUpSpine.setLocalZOrder(node.getLocalZOrder());
                showUpSpine.setOrderOfArrival(node.getOrderOfArrival() - 0.1);
                showUpSpine.setAnimation(0, "animation", false);
                showUpSpine.setCompleteListenerRemove();
                showUpSpine.setScale(Math.min(1, 2 * node.height / showUpSpine.height));
            })
        );
    }

    const duration = 0.2;

    if (options.customSqueeze) {
        actions.push(
            new cc.Spawn(
                new cc.ScaleTo(0, 0),
                new cc.Show(),
                new cc.MoveBy(0.23, -move.x, -move.y).easing(cc.easeBackOut()),
                new cc.Sequence(
                    new cc.ScaleTo(duration * 0.5, 0.66, 0.83),
                    new cc.ScaleTo(duration * 0.5, 1.05, 1.07),
                    new cc.ScaleTo(duration * 0.65, 0.97),
                    new cc.ScaleTo(duration * 0.5, 0.95, 1.02),
                    new cc.ScaleTo(duration * 0.35, 1)
                )
            )
        );
    } else {
        actions.push(
            new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(0.15),
                new cc.FadeOut(0),
                new cc.Show()
            )
        );

        const scale = 1.03;
        actions.push(new cc.Spawn(
            new cc.ScaleTo(duration, baseScaleX / scale, baseScaleY * scale),
            new cc.MoveBy(duration, -move.x * 1.1, -move.y * 1.1).easing(cc.easeBackOut()),
            new cc.FadeIn(duration / 2)
        ));
        actions.push(new cc.Spawn(
            new cc.ScaleTo(duration * 0.5, baseScaleX, baseScaleY),
            new cc.MoveTo(duration * 0.5, basePosition.x, basePosition.y)
        )); 
    }
    return new cc.Sequence(actions).setFinalize(() => {
        node.setScale(baseScaleX, baseScaleY);
        node.setPosition(basePosition);
    });
};

StandartAnimations.disappear = function (node, options) {
    options = options || {};

    let dustActions;

    if (options.dust) {
        const dustAnimationName = "animation";
        const dust = new cleverapps.Spine(bundles.animations_library.jsons.clean_json);
        dustActions = [new cc.CallFunc(() => {
            dust.setPositionRound({ x: { align: "center" }, y: { align: "bottom" } });
            node.addChild(dust);
            dust.replaceParentSamePlace(cleverapps.scenes.getMovingNode(dust));
            dust._setGlobalZOrder(true);
            dust.setAnimation(0, dustAnimationName, false);
            dust.setScale(node.width / dust.width);
        }),
        new cc.DelayTime(dust.getAnimationData(dustAnimationName).duration)
        ];
    }

    const position = node.getPosition();
    const duration = options.duration || 0.15;
    const move = options.move || cleverapps.styles.StandartAnimations.showUp.fromBottom;
    const jumpMove = {
        x: (move.x + node.width * Math.sign(move.x)) * 0.3,
        y: (move.y + node.height * Math.sign(move.y)) * 0.3
    };

    const actions = [];
    const scale = options.scale || 1.06;

    actions.push(
        new cc.Spawn(
            new cc.ScaleTo(duration * 0.3, scale, 1 / scale),
            new cc.MoveBy(duration * 0.3, -jumpMove.x, -jumpMove.y).easing(cc.easeIn(1))
        )
    );
    actions.push(
        new cc.Spawn(
            new cc.ScaleTo(duration * 0.7, 1, 1).easing(cc.easeOut(1)),
            new cc.MoveBy(duration * 0.7, move.x + jumpMove.x, move.y + jumpMove.y).easing(cc.easeOut(1)),
            new cc.FadeOut(duration / 2)
        )
    );

    if (!options.keepVisible) {
        actions.push(new cc.Hide());
    }

    const res = dustActions ? new cc.Spawn([new cc.Sequence(actions), new cc.Sequence(dustActions)]) : new cc.Sequence(actions);

    return res.setFinalize(() => {
        if (!options.keepVisible) {
            node.setVisible(false);
        }
        node.setPosition(position);
    });
};

StandartAnimations.blink = function (node, options) {
    if (typeof gl === "undefined") {
        return undefined;
    }

    options = options || {};
    const duration = options.duration || 2;
    const brightness = options.brightness || 0.15;
    const color = [brightness, brightness, brightness];

    return new cc.Sequence(
        new cc.Flicker(
            duration / 2,
            color
        ).easing(cc.easeInOut(2)),
        new cc.Flicker(
            duration / 2,
            color,
            true
        ).easing(cc.easeInOut(2))
    );
};

StandartAnimations.outline = function (node, options) {
    options = options || {};

    return new cc.CallFunc(() => {
        let outline;

        if (node instanceof cleverapps.Spine) {
            outline = new cleverapps.Spine(node.jsonName);

            let animationName;
            if (outline.hasAnimation("idle")) {
                animationName = "idle";
            } else if (outline.hasAnimation("animation")) {
                animationName = "animation";
            }

            animationName = options.animationName || animationName;

            if (outline.hasAnimation(animationName)) {
                outline.setAnimation(0, animationName, true);
                if (options.animationName) {
                    node.setAnimation(0, animationName, true);
                } else {
                    outline.clearTrack(0);
                }
            }
        } else {
            outline = new cc.Sprite(node.getSpriteFrame());
        }

        const width = cleverapps.styles.StandartAnimations.outline.width;
        outline.setScale((outline.width + width) / outline.width);
        outline.setPositionRound(node.width / 2, node.height / 2);
        node.addChild(outline, -1);

        cleverapps.UI.outlineSprite(outline);

        node.outlineSprite = outline;
    });
};

cleverapps.styles.StandartAnimations = {
    squeezeJump: {
        jumpHeight: 180
    },
    shake: {
        dx: 1,
        dy: 1
    },
    shift: {
        shift: 25
    },
    outline: {
        width: 16
    },
    hovering: {
        offsetY: 25,
        particles: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },
    jumpCollect: {
        jump: {
            height: 200,

            right: {
                x1: 50,
                x2: 150,
                y1: -50,
                y2: -100
            },

            left: {
                x1: -50,
                x2: -150,
                y1: -50,
                y2: -100
            },

            both: {
                x1: -50,
                x2: 50,
                y1: -50,
                y2: -100
            }
        },

        longJump: {
            height: 100,

            right: {
                x1: 550,
                x2: 650,
                y1: -50,
                y2: -100
            }
        }
    },
    squeeze: {
        smallHeight: 100
    },
    coinCloud: {
        text: {
            scale: 1,
            position: {
                x: {
                    align: "center",
                    dx: 0
                },
                y: {
                    align: "center",
                    dy: 0
                }
            }
        },
        sparks: {
            position: {
                x: {
                    align: "center",
                    dx: 0
                },
                y: {
                    align: "center",
                    dy: 0
                }
            }
        },
        coins: [
            [
                {
                    radiusX: 80,
                    radiusY: 80,
                    count: 6,
                    scale: 1
                }
            ],
            [
                {
                    radiusX: 40,
                    radiusY: 40,
                    count: 8,
                    scale: 0.8
                },
                {
                    radiusX: 15,
                    radiusY: 15,
                    count: 3,
                    scale: 1
                }
            ],
            [
                {
                    radiusX: 100,
                    radiusY: 140,
                    count: 16,
                    scale: 0.8
                },
                {
                    radiusX: 70,
                    radiusY: 98,
                    count: 12,
                    scale: 1
                },
                {
                    radiusX: 40,
                    radiusY: 55,
                    count: 6,
                    scale: 1.3
                }
            ]
        ],
        showTextTime: [0.6, 1, 1]
    },
    showUp: {
        fromBottom: {
            x: 0,
            y: -35
        }
    }
};

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    COINS_TEXT_STROKE: {
        size: 3,
        color: cleverapps.styles.COLORS.COINS_REWARD_STROKE_COLOR
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    COINS_REWARD_TEXT: {
        size: 120,
        color: cleverapps.styles.COLORS.COINS_REWARD_YELLOW,
        stroke: cleverapps.styles.DECORATORS.COINS_TEXT_STROKE
    }
});

const CoinCloud = {};
CoinCloud.SMALL = 0;
CoinCloud.MEDIUM = 1;
CoinCloud.BIG = 2;