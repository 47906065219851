/**
 * Created by slava on 4/4/17.
 */

const ABExperiments = function () {
    this.groups = {};
    this.updateCallbacks = [];

    const json = bundles.abtest.jsons.abtest_json.getJson();

    this.remoteJson = new RemoteJson(json);
};

ABExperiments.STATUS_DRAFT = "draft";
ABExperiments.STATUS_LIVE = "live";
ABExperiments.STATUS_ADOPTED = "adopted";
ABExperiments.STATUS_REVERTED = "reverted";

ABExperiments.prototype.initialize = function (callback) {
    callback = cleverapps.wait(2, callback);

    this.remoteJson.initialize(callback);
    this.remoteJson.fetch(callback);
};

ABExperiments.prototype.updateTests = function () {
    const tests = this.remoteJson.getJson();
    ABExperiments.Tests = JSON.parse(JSON.stringify(tests));

    for (const id in ABExperiments.Tests) {
        if (typeof ABExperiments.Tests[id] !== "object") {
            delete ABExperiments.Tests[id];
        }
    }

    ABExperiments.initializeTests();
};

ABExperiments.prototype.updateGroups = function () {
    this.updateTests();

    this.groups = {};

    const data = cleverapps.dataLoader.load(DataLoader.TYPES.ABTEST) || {};

    for (const id in ABExperiments.Tests) {
        const test = ABExperiments.Tests[id];
        const start = test.start ? new Date(test.start).getTime() : 0;

        if (test.status === ABExperiments.STATUS_DRAFT && !cleverapps.config.debugMode) {
            continue;
        }

        if (Date.now() < start || cleverapps.user.registered < start) {
            continue;
        }

        if (cleverapps.user.checkAvailable(test.available) && cleverapps.paymentsHistory.checkAvailable(test.available)) {
            if (data[id] !== undefined && test.status !== ABExperiments.STATUS_REVERTED && test.status !== ABExperiments.STATUS_ADOPTED) {
                this.setGroup(test, data[id]);
                continue;
            }

            this.setGroup(test, ABExperiments.calcGroup(test));
        }
    }

    this.updateCallbacks.forEach((callback) => {
        callback();
    });
};

ABExperiments.prototype.whenGroupsUpdated = function (callback) {
    this.updateCallbacks.push(callback);

    if (ABExperiments.Tests) {
        callback();
    }
};

ABExperiments.prototype.saveGroup = function (test, group) {
    const data = cleverapps.dataLoader.load(DataLoader.TYPES.ABTEST) || {};
    data[test.id] = group;
    cleverapps.dataLoader.save(DataLoader.TYPES.ABTEST, data);
};

ABExperiments.prototype.getGroup = function (test) {
    if (!test) {
        return undefined;
    }

    return this.groups[test.id];
};

ABExperiments.prototype.setGroup = function (test, group) {
    this.groups[test.id] = ABExperiments.parseGroup(group);
};

ABExperiments.prototype.logEvent = function (test, event, params) {
    const group = this.getGroup(test).toLowerCase();
    if (group !== undefined) {
        cleverapps.eventLogger.logEvent(`ab_${test.id}_${group}_${event}`, params);
    }
};

ABExperiments.prototype.allLogEvent = function (event, params) {
    params = params || {};

    const location = cleverapps.meta.getSelectedLocation();
    const expedition = params.expedition || location.isExpedition() && location.locationId;

    for (const id in this.groups) {
        const test = ABExperiments.Tests[id];

        if ([ABExperiments.STATUS_ADOPTED, ABExperiments.STATUS_REVERTED].includes(test.status)) {
            continue;
        }

        if (test.expeditions && test.expeditions.indexOf(expedition) === -1) {
            continue;
        }
        const group = this.getGroup(test).toLowerCase();
        cleverapps.eventLogger.logEvent(`ab_${test.id}_${group}_${event}`, params);
    }
};

ABExperiments.MSSTART_IAP = function () {
    return cleverapps.abExperiments.getGroup(ABExperiments.Tests.msstartiap) === "A";
};

ABExperiments.HAS_MSSTART_IAP = function () {
    return cleverapps.abExperiments.getGroup(ABExperiments.Tests.msstartiap) !== undefined;
};

ABExperiments.calcGroup = function (test, userId) {
    if (cleverapps.isLocalhost()) {
        return "A";
    }

    if (test.groupBySource) {
        return ABExperiments.parseGroup(test.groupBySource[connector.info.source]);
    }

    if (ABExperiments.STATUS_REVERTED === test.status) {
        return ABExperiments.parseGroup(test.groups[test.groups.length - 1]);
    }

    if (ABExperiments.STATUS_ADOPTED === test.status) {
        if (test.choice) {
            return ABExperiments.parseGroup(test.choice);
        }
        return "A";
    }

    let group;
    const rnd = cleverapps.Random.seededDouble((userId || connector.platform.getUserID()) + test.id);

    if (test.groups.length > 2) {
        const groupWeight = 1 / test.groups.length;
        group = test.groups[Math.floor(rnd / groupWeight)];
    } else {
        group = rnd > test.AWeight ? "B" : "A";
    }

    return group;
};

ABExperiments.parseGroup = function (group) {
    if (typeof group === "number") {
        return "ABCD".charAt(group);
    }
    return group;
};

ABExperiments.initializeTests = function () {
    for (const name in ABExperiments.Tests) {
        const test = ABExperiments.Tests[name];
        test.id = name;
        test.groups = test.groups ? test.groups.map((group) => ABExperiments.parseGroup(group)) : ["A", "B"];
        test.AWeight = test.AWeight || 0.3;
        test.status = test.status || (test.available && test.available.debugMode ? ABExperiments.STATUS_DRAFT : ABExperiments.STATUS_LIVE);
    }
};

if (typeof cc === "undefined") {
    ABExperiments.Tests = require("./abtest.json");
    ABExperiments.initializeTests();

    module.exports = ABExperiments;
}
