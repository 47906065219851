/**
 * Created by razial on 09.01.2025.
 */

const FarmAdapter = function () {

};

FarmAdapter.prototype.load = function () {

};

FarmAdapter.prototype.save = function () {

};

FarmAdapter.prototype.updateInfo = function (data) {
    cleverapps.dataLoader.save(DataLoader.TYPES.FARM, data);
};

FarmAdapter.prototype.getInfo = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.FARM) || {};
};

FarmAdapter.prototype.loadLocation = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.FARM) || {};
};

FarmAdapter.prototype.saveLocation = function (locationId, data) {
    cleverapps.dataLoader.save(DataLoader.TYPES.FARM, data);
    
    cleverapps.synchronizer.addUpdateTask("metha");
};

FarmAdapter.prototype.reset = function () {

};